<template>
  <el-select :value="value" @change="handleChange" v-bind="$attrs">
    <el-option
      v-for="(item, index) in options"
      :key="index"
      :label="item.questionTypeValue"
      :value="item[valueKey]"
    />
  </el-select>
</template>

<script>
import {
  commonTypeList,
} from '@/api/question'

export default {
  name: 'FormItemQuestionType',
  props: {
    value: {
      type: [Number, String],
      default: undefined
    },
    valueKey: {
      type: String,
      default: 'questionTypeName'
    },
    subsectionId: {
      type: Number,
      default: undefined
    }
  },
  data () {
    return {
      options: [],
    }
  },
  watch: {
    subsectionId: {
      immediate: true,
      handler (val) {
        if (val) {
          this.loadData(val)
        } else {
          this.options = []
        }
      }
    }
  },
  methods: {
    loadData (subsectionId) {
      return commonTypeList(subsectionId).then(res => {
        if (res.code === 0) {
          this.options = res.data
            ? res.data.sort((a, b) => a.sort - b.sort)
            : []
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    handleChange (val) {
      this.$emit('input', val)
    }
  }
}
</script>
