/**
 * 科目
 */

export const Subject = [
  { value: '语文', id: 'CHINESE' },
  { value: '数学', id: 'MATHEMATICS' },
  { value: '英语', id: 'ENGLISH' },
  { value: '物理', id: 'PHYSICS' },
  { value: '化学', id: 'CHEMISTRY' },
  { value: '生物学', id: 'BIOLOGY' },
  { value: '历史', id: 'HISTORY' },
  { value: '地理', id: 'GEOGRAPHY' },
  { value: '道德与法治', id: 'MORALITY_AND_RULE_OF_LAW' },
  { value: '思想政治', id: 'POLITICS' },
  { value: '体育与健康', id: 'SPORTS_AND_HEALTH' },
  { value: '艺术·音乐', id: 'MUSIC' },
  { value: '艺术·美术', id: 'FINE_ARTS' },
  { value: '科学', id: 'SCIENCE' },
  { value: '信息科技', id: 'INFORMATION_TECHNOLOGY' },
  { value: '通用技术', id: 'GENERAL_TECHNOLOGY' },
  { value: '劳动与技术', id: 'LABOR_AND_TECHNOLOGY' },
  { value: '心理健康', id: 'MENTAL_HEALTH' },
  { value: '安全教育', id: 'SAFETY_EDUCATION' },
]
