import OSS from 'ali-oss'
import { aliSTS } from '@/api/common'

//#region _example_
// const _example_ = {}
// _example_.result = {
//   accessKeyId: "STS.NUbXsJ4bQtfbXPnYTtm3AomAH",
//   accessKeySecret: "5UCuLuzrJzABD8BJibd62nNvGUTm6EPwjnnVSj7YKaHh",
//   expiration: "2023-02-27T11:02:15Z",
//   securityToken: "CAISiwJ1q6Ft5B2yfSjIr5bXE8n+2b1ww6SJWnbfvVQhYbxtgKjqqjz2IH5OdXFhBOEbtf40m29W5/wYlrh+W4NIX0rNaY5t9ZlN9wqkbtJwfwJJbORW5qe+EE2/VjTZvqaLEcibIfrZfvCyESOm8gZ43br9cxi7QlWhKufnoJV7b9MRLGLaBHg8c7UwHAZ5r9IAPnb8LOukNgWQ4lDdF011oAFx+wgdgOadupTCs0aF1QyklLBL/divcsWeApMybMslYbCcx/drc6fN6ilU5iVR+b1+5K4+ommd74nMXggMuE/dbLWKo4c1NnxwYqkrBqhDt+Pgkv51vOPekYntwgpKJ/tSVynPwR0WGlIqn4kagAFzdBbEGm2irC8atZJb3z8xe0VA4PLEJwvFIU8PWyA49O4WAbaE9Ug98pZTRMB9E2oagA4kFQpCZE2IaaEABysA/wX6jUGb0ok+vegXozH79T+AWToTPigrZ7Fk0v3m8yBBfh3kw1+V5Va0Lt9PKC3oqhFu5EMYgNbn8pGB6LFo7g==",
//   statusCode: "200"
// }
//#endregion

const region = process.env.VUE_APP_OSS_REGION
const bucket = process.env.VUE_APP_OSS_BUCKET
const endpoint = process.env.VUE_APP_OSS_ENDPOINT

let instance = null

/**
 * 刷新Token
 * @returns STS鉴权报文
 */
function refreshSTSToken () {
  return aliSTS().then(res => {
    if (res.data.statusCode !== '200') {
      throw new Error('STS error')
    }
    return res.data
  })
}

/**
 * 请求回调
 * @param {Function} action
 * @returns {*}
 */
function applyTokenDo (action) {
  if (instance) {
    return action(instance)
  }
  return refreshSTSToken().then(creds => {
    instance = new OSS({
      region: region,
      bucket: bucket,
      endpoint: endpoint,
      accessKeyId: creds.accessKeyId,
      accessKeySecret: creds.accessKeySecret,
      stsToken: creds.securityToken,
      refreshSTSToken: refreshSTSToken,
    })
    return action(instance)
  })
}

export default {
  put: function (filePath, file) {
    const action = client => client.put(filePath, file)
    return applyTokenDo(action)
    // .then(res => { console.log('---- upload: ', res); return res })
  }
}

export function ossBaseUrl () {
  switch (process.env.NODE_ENV) {
    case 'production':
      return 'prod'
    case 'preview':
      return 'pre'
    default:
      return 'dev'
  }
}
