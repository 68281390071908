export const SETTING = {
  NUMBER: {
    ZERO: 0,
    ONE: 1,
    TWO: 2,
    N1024: 1024,
    N200: 200,
    N1000: 1000
  },
  STRING: {
    MSG_BOX_STATUS: {
      ERROR: 'error',
      WARN: 'warning',
      SUCCESS: 'success'
    },
    OVERTIME: 'OVERTIME'
  },
  CACHE: {
    USER_LOGIN_INFO: 'user.login.info',
    ACCESS_TOKEN: 'user.access.token',
    PROVINCE_ID: 'user.province.id',
    REFRESH_TOKEN: 'user.refresh.token',
    USER_PHONE: 'user.phone'
  },
  URL: {
    IMAGE_URL: process.env.VUE_APP_IMAGE_BASE,
  },
  HTTP: {
    METHOD: {
      POST: 'POST',
      GET: 'GET'
    }
  }
}
