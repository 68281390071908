import Vue from 'vue'
import Vuex from 'vuex'
import branchImport from './modules/branchImport'
import getters from './getters'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    branchImport
  },
  getters
})
