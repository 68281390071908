<template>
  <div class="question" v-if="detail" :class="{ 'active': active }">
    <div class="cnt">
      <ui-mathpad :value="detail.stem" />
    </div>
    <div class="types el-row el-row--flex">
      <div class="el-col">难度：{{ detail.degreeOfDifficultyName }}</div>
      <div class="el-col">题型：{{ detail.questionTypeName }}</div>
      <div class="el-col lighten">更新时间：{{ detail.updateTime }}</div>
      <div class="el-col ext">
        <slot name="extend"></slot>
      </div>
    </div>

    <div class="detail">
      <div v-if="detail.answer" class="el-row--flex">
        <div class="label">【答案】</div>
        <ui-mathpad ref="answer" :value="detail.answer" />
      </div>
      <div v-if="detail.analysis" class="el-row--flex">
        <div class="label">【解析】</div>
        <ui-mathpad ref="answer" :value="detail.analysis" />
      </div>
      <div class="el-row--flex" v-if="detail.knowledgeNames && detail.knowledgeNames.length">
        <div class="label">【章节】</div>
        <div>
          <span v-for="(item, index) in detail.knowledgeNames" :key="index" class="key-item">
            {{ item }}
          </span>
        </div>
      </div>
      <div class="el-row--flex" v-if="detail.knowledgeNames && detail.knowledgeNames.length">
        <div class="label">【知识点】</div>
        <div>
          <span v-for="(item, index) in detail.knowledgeNames" :key="index" class="key-item">
            {{ item }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'QuestionItem',
  props: {
    active: {
      type: Boolean,
      default: false
    },
    detail: {
      type: Object,
      default: null
    }
  },
  watch: {
    question: {
      handler (val) {

      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
$space: 10px;

.lighten {
  color: #999;
}

.question {
  margin: $space 0;
  border: 1px solid #E0E0E0;
  border-radius: 5px;
  overflow: hidden;

  &.active {
    border-color: #18C2C2;

    .detail {
      display: block;
    }
  }

  .cnt {
    padding: $space;
  }

  .types {
    font-size: 13px;
    line-height: 32px;
    background: rgba(153, 153, 153, 0.1);

    .el-col {
      margin: 0 30px 0 $space;
    }
  }

  .el-row {
    .ext {
      flex: 1;
      margin-right: $space;
      text-align: right;
    }
  }

  .detail {
    display: none;

    > .el-row--flex {
      margin: $space 0;
    }

    .label {
      margin-right: 5px;
    }

    .key-item {
      font-size: 13px;
      line-height: 20px;
      padding: 4px;
      margin-right: 5px;
      background: rgba(30, 177, 128, 0.1);
      border-radius: 4px;
      color: rgba(30, 177, 128, 1);
    }
  }
}
</style>
