import request from '@/utils/request'

/**
 * 题库列表
 * @param data
 * @returns {AxiosPromise}
 */
export function questionList (data) {
  return request({
    url: '/recordQuestion/list',
    method: 'POST',
    data
  })
}

/**
 * 题目详情
 * @param questionId 题目ID
 * @param materialId 教材ID
 * @return {AxiosPromise}
 */
export function questionDetail (questionId, materialId) {
  return request({
    url: '/recordQuestion/detail',
    method: 'GET',
    params: {
      questionId,
      materialId,
    }
  })
}

/**
 * 题库新增
 * @param data
 * @returns {AxiosPromise}
 */
export function questionAdd (data) {
  return request({
    url: '/recordQuestion/save',
    method: 'POST',
    data
  })
}

/**
 * 题库新增 (新增组合题)
 * @param data
 * @returns {AxiosPromise}
 */
export function questionThemeAdd (data) {
  return request({
    url: '/recordQuestion/theme/save',
    method: 'POST',
    data
  })
}

/**
 * 题目修改
 * @param data
 * @returns {AxiosPromise}
 */
export function questionEdit (data) {
  return request({
    url: '/recordQuestion/edit',
    method: 'POST',
    data
  })
}

/**
 * 根据教材ID和题库ID查看详情
 * @param data
 * @param {String} data.questionId 题库ID
 * @param {Number} data.teachingMaterialId 教材ID
 * @returns {AxiosPromise}
 */
export function questionFind (data) {
  return request({
    url: '/recordQuestion/findByQuestionBankIdAndTeachingMaterialId',
    method: 'POST',
    data
  })
}

/**
 * 删除未审核未打标签题目
 * @param {String} questionId
 * @returns {AxiosPromise}
 */
export function questionRemove (questionId) {
  return request({
    url: '/recordQuestion/question/remove',
    method: 'GET',
    params: {
      questionId
    }
  })
}

/**
 * 题目查重
 * @param {Object} data
 * @param {String} data.stem 题干
 * @param {Number} data.subsectionId 学段学科ID
 * @returns {AxiosPromise}
 */
export function checkRepeat (data) {
  return request({
    url: '/recordQuestion/check/repeat',
    method: 'POST',
    data
  })
}

/**
 * 题目上下架
 * @param {String} questionId 题目Id
 * @param {Boolean} OnShelf 是否上架
 * @returns {AxiosPromise}
 */
export function questionRackUpAndDown (questionId, OnShelf) {
  return request({
    url: '/recordQuestion/rackUpAndDown',
    method: 'POST',
    data: {
      questionId,
      dataStatus: OnShelf ? 'PUT_ON_THE_SHELF' : 'OFF_THE_SHELF'
    }
  })
}

/**
 * 批量保存坐标
 * @param data
 * @returns {AxiosPromise}
 */
export function questionSaveCoordinate (data) {
  return request({
    url: '/recordQuestion/saveCoordinate',
    method: 'POST',
    data
  })
}

/**
 * 批量设置题目排序权重
 * @param data
 * @returns {AxiosPromise}
 */
export function questionSaveSortAll (data) {
  return request({
    url: '/recordQuestion/saveSortAll',
    method: 'POST',
    data
  })
}
