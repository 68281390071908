<template>
  <div id="app">
    <keep-alive :exclude="exclude">
      <router-view v-if="$route.meta.keepAlive" />
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" />
  </div>
  <!-- <div id="app">
    <router-view />
  </div> -->
</template>

<script>

export default {
  name: 'app',
  data () {
    return {
      exclude: null
    }
  }
}
</script>

<style>
</style>
