<template>
  <el-select
    :value="innerValue"
    v-bind="$attrs"
    @input="handleSelect"
  >
    <el-option
      v-for="item in options"
      :key="item.teachingMaterialId"
      :label="item.teachingMaterialName"
      :value="item.teachingMaterialId">
    </el-option>
  </el-select>
</template>

<script>
import { getStandardMaterialList } from '@/api/teachingMaterial'

export default {
  name: 'TeachingMaterial',
  props: {
    value: {
      type: Number,
      default: undefined
    },
    editionId: {
      type: Number,
      default: undefined
    }
  },
  data () {
    return {
      options: [],
      innerValue: undefined
    }
  },
  watch: {
    value: {
      immediate: true,
      handler (val) {
        this.$nextTick(() => {
          this.innerValue = val
        })
      }
    },
    editionId: {
      immediate: true,
      handler (val) {
        // 请求教材列表
        this.options = []
        this.innerValue = undefined
        if (val) {
          this.loadOptions(val)
        }
      }
    }
  },
  methods: {
    loadOptions (subjectSection) {
      return getStandardMaterialList(subjectSection).then(res => {
        if (res.code === 0) {
          this.options = res.data
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    handleSelect (val) {
      this.innerValue = val
      this.$emit('input', val)
      this.$emit('change', val)
    }
  }
}
</script>

<style scoped>

</style>
