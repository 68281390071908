import { ApplyStatus } from '@/config/enums/ApplyStatus'
import { Grade } from '@/config/enums/Grade'
import { QuestionStatus } from '@/config/enums/QuestionStatus'
import { QuestionType } from '@/config/enums/QuestionType'
import { Subject } from '@/config/enums/Subject'

// 年级
export const grade = Grade

export const getGradeName = id => {
  return getName(grade, id)
}

function getName (data, id) {
  let res = null
  data.forEach(item => {
    if (item.id === id) {
      res = item.value
    }
  })
  return res
}

// 申请状态
export const applyStatus = ApplyStatus

// 题目难易程度
export const degree = [
  {
    id: 'SIMPLE',
    value: '容易'
  },
  {
    id: 'EASILY',
    value: '较易'
  },
  {
    id: 'COMMONLY',
    value: '一般'
  },
  {
    id: 'HARDER',
    value: '较难'
  },
  {
    id: 'DIFFICULTY',
    value: '困难'
  }
]

export function getDegree (id) {
  return getName(degree, id)
}

// 科目
export const subject = Subject

export function getSubjectName (id) {
  return getName(subject, id)
}

// 题库状态
export function getQuestionStatusName (id) {
  return getName(QuestionStatus, id)
}

// 题目类型
export const questionType = QuestionType

export function getQuestionTypeName (id) {
  return getName(questionType, id)
}

// 班级
export const classEnum = ((() => {
  const clss = []
  for (let i = 0; i < 50; i++) {
    clss.push(i + 1)
  }
  return clss
})())

// 入学年份
export const enrollmentYear = ((() => {
  const years = []
  for (let i = 2010; i < 2031; i++) {
    years.push(i)
  }
  return years
})())

// 教年
export const teachingYear = ((() => {
  const years = []
  for (let i = 0; i < 50; i++) {
    years.push(i + 1)
  }
  return years
})())
