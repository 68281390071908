import Quill from 'quill'
import { JaxBlot } from './JaxBlot'
import { texRender } from '../../utils'

export class JaxModule {
  static register () {
    Quill.register('formats/jax', JaxBlot)
  }

  constructor (quill, options) {
    this.quill = quill

    quill.root.addEventListener('dblclick', this.handleEdit.bind(this), false)
  }

  handleEdit (evt) {
    const root = evt.currentTarget
    const tagName = JaxBlot.tagName.toLowerCase()
    const className = JaxBlot.className
    let el = evt.target
    while (el && el !== root) {
      if (el.tagName.toLowerCase() === tagName && el.classList.contains(className)) {
        evt.preventDefault()
        const latex = el.dataset.latex
        if (latex) {
          const quill = this.quill
          const range = quill.getSelection()
          quill.setSelection(range.index, 1)
          window.jexEditor.edit(latex, function (action, params) {
            window.MathQueue().then(() => {
              const mjx = texRender(params.data)
              const selection = quill.getSelection()
              const index = selection ? selection.index : quill.getLength()
              const len = selection ? 1 : 0
              if (len) {
                quill.deleteText(index, len)
              }
              quill.insertEmbed(index, 'jax', mjx, 'user')
              quill.setSelection(index + 1, 0)
            })
          })
        }
        break
      }
      el = el.parentNode
    }
  }
}
