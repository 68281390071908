<template>
  <div class="page" v-loading="loading">
    <!-- 搜索 -->
    <div class="ui-panel">
      <div class="ui-panel-head">
        <div class="el-row is-justify-space-between el-row--flex">
          <div class="el-col">
            <div class="ui-panel-title">教辅题库</div>
          </div>
          <div class="el-col">
            <div class="ui-space">
              <el-button type="warning" size="small" @click="handleNav('selectMaterial')">
                新增 <i class="el-icon-plus"></i>
              </el-button>
              <!--<el-dropdown class="header-btn" @command="handleNav">-->
              <!--  <el-button type="warning">-->
              <!--    新增<i class="el-icon-arrow-down el-icon&#45;&#45;right"></i>-->
              <!--  </el-button>-->
              <!--  <el-dropdown-menu slot="dropdown">-->
              <!--    <el-dropdown-item command="selectMaterial">批量新增</el-dropdown-item>-->
              <!--    <el-dropdown-item command="QuestionAdd">单题录入</el-dropdown-item>-->
              <!--  </el-dropdown-menu>-->
              <!--</el-dropdown>-->

              <el-dropdown class="header-btn" @command="handleSubjectCommand" trigger="click">
                <el-button type="text">
                  {{ curSubject ? `当前：${curSubject.subsection}${curSubject.subjectValue}` : '请选择学科' }}<i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <div v-for="(section, index) in subjectList" :key="index" class="subject-group">
                    <div class="subject-head">{{ section.subsection }}</div>
                    <div class="subject-list">
                      <el-dropdown-item
                        v-for="item in section.subjects"
                        :key="item.id"
                        :class="{ selected: curSubject && item.id === curSubject.id }"
                        class="subject-item"
                        type="text"
                        :command="{
                          item,
                          section
                        }"
                      >{{ item.subjectValue }}</el-dropdown-item>
                    </div>
                  </div>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
        </div>
      </div>

      <!-- 搜索表单 -->
      <el-form class="searchForm" size="small" :model="searchData">
        <el-form-item label="难度：" label-width="60px">
          <el-radio-group v-model="searchData.degreeOfDifficulty" @change="handleSearch">
            <el-radio label="">全部</el-radio>
            <el-radio v-for="item in difficultyOptions" :key="item.value" :label="item.value">{{ item.label }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="题型：" label-width="60px">
          <el-radio-group v-model="searchData.questionType" @change="handleSearch">
            <el-radio label="">全部</el-radio>
            <el-radio
              v-for="item in typeOptions"
              :key="item.questionTypeName"
              :label="item.questionTypeName"
              class="search-radio"
            >{{ item.questionTypeValue }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="状态：" label-width="60px">
          <el-radio-group v-model="searchData.dataStatus" @change="handleSearch">
            <el-radio label="">全部</el-radio>
            <el-radio label="PUT_ON_THE_SHELF">已上架</el-radio>
            <el-radio label="OFF_THE_SHELF">已下架</el-radio>
          </el-radio-group>
        </el-form-item>
        <div class="el-row el-row--flex">
          <div class="el-form-item el-form-item--small">
            <div class="el-form-item__label" style="width: 60px;">更多：</div>
          </div>
          <el-form-item class="form-item-inline">
            <cmp-edition
              v-model="editionGradeValue"
              :subsectionId="searchData.subsectionId"
              clearable
              placeholder="请选择版本"
              @change="changeEdition"
            />
          </el-form-item>
          <el-form-item class="form-item-inline">
            <cmp-teaching-material
              v-model="searchData.teachingMaterialId"
              :editionId="editionGradeValue"
              clearable
              placeholder="请选择教辅"
              @change="handleSearch"
            />
          </el-form-item>
          <el-form-item class="form-item-inline">
            <el-input-number v-model="searchData.pageNumber" placeholder="页码" @change="handleSearch" :min="1" :step="1" />
          </el-form-item>
        </div>
      </el-form>
    </div>

    <div class="ui-panel">
      <div class="ui-panel-head">
        <div class="el-row is-justify-space-between el-row--flex">
          <div class="el-col">
            <div style="line-height: 32px;">共：{{ page.total }}道题</div>
          </div>
          <div class="el-col">
            <el-radio-group v-model="searchData.questionStatus" size="small" @change="handleSearch">
              <el-radio-button label="TO_BE_PROCESSED">待加工</el-radio-button>
              <!-- <el-radio-button label="AUDIT_FAILED">审核未通过</el-radio-button>-->
              <el-radio-button label="COMPLETED">已完成</el-radio-button>
            </el-radio-group>
          </div>
        </div>
      </div>

      <!-- 题目列表-->
      <div class="question-list" v-if="page.total">
        <div
          class="question-item"
          v-for="(item, index) in questionList"
          :key="item.id"
          @click="curQuestionIndex = index"
        >
          <QuestionItem :detail="item" :active="index === curQuestionIndex">
            <template #extend>
              <el-button size="small" type="text" @click="handleRackUp(item, item.dataStatus !== 'PUT_ON_THE_SHELF')">
                {{ item.dataStatus !== 'PUT_ON_THE_SHELF' ? '上架' : '下架' }}
              </el-button>

              <el-dropdown @command="handleOperate" v-if="item.source.length > 1" size="small">
                <el-button size="small" type="text" class="operate-group">
                  操作<i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <div v-for="source in item.source" :key="source.teachingMaterialId" class="operate">
                    <div class="hd" :title="source.teachingMaterialCn">教辅：{{ source.teachingMaterialCn }}</div>
                    <el-dropdown-item
                      v-if="source.coordinateInfo && source.coordinateInfo.length"
                      type="text"
                      :command="{
                        type: 'preview',
                        question: item,
                        data: source.coordinateInfo
                      }"
                    >原图预览</el-dropdown-item>
                    <el-dropdown-item
                      v-else
                      type="text"
                      :command="{
                        type: 'draw',
                        question: item,
                        data: source.teachingMaterialId
                      }"
                    >画坐标</el-dropdown-item>
                    <el-dropdown-item
                      type="text"
                      :command="{
                        type: 'edit',
                        question: item,
                        data: source.teachingMaterialId
                      }"
                    >编辑</el-dropdown-item>
                  </div>
                </el-dropdown-menu>
              </el-dropdown>

              <template v-else>
                <!-- source 属性含至少一个教辅信息对象 -->
                <el-button
                  v-if="item.source[0].coordinateInfo && item.source[0].coordinateInfo.length"
                  size="small"
                  type="text"
                  @click="handlePreview(item, item.source[0])"
                >原图预览</el-button>
                <el-button
                  v-else
                  size="small"
                  type="text"
                  @click="drawCoordinates(item.source[0].teachingMaterialId, item.questionId)"
                >画坐标</el-button>
                <el-button
                  size="small"
                  type="text"
                  @click="handleOperate({
                    type: 'edit',
                    question: item,
                    data: item.source[0].teachingMaterialId
                  })">编辑</el-button>
              </template>
            </template>
          </QuestionItem>
        </div>
      </div>
      <div v-else>
        <el-empty />
      </div>

      <!-- 分页 -->
      <div class="ui-pagination">
        <el-pagination
          layout="total, prev, pager, next, jumper"
          @current-change="handleCurrentChange"
          :current-page.sync="page.currentPage"
          :page-size="page.size"
          :total="page.total"
        />
      </div>
    </div>

    <!-- 图片预览 -->
    <ui-gallery :visible.sync="preview.visible" :list="preview.list">
      <div slot="footer" class="preview-footer">
        <el-button
          type="primary"
          size="small"
          @click="drawCoordinates(preview.data.teachingMaterialId, preview.data.questionId)"
        >编辑坐标</el-button>
      </div>
    </ui-gallery>

    <el-dialog
      title="批量新增"
      width="400px"
      :center="true"
      :visible.sync="dlgMaterial.visible"
    >
      <el-form size="small" label-width="6em" class="questionInfo">
        <el-form-item label="版本" class="form-item">
          <cmp-edition
            v-model="dlgMaterial.standardTeachingMaterialId"
            :subsectionId="this.searchData.subsectionId"
            placeholder="请选择"
          />
        </el-form-item>
        <el-form-item label="教辅" class="form-item">
          <cmp-teaching-material
            v-model="dlgMaterial.teachingMaterialId"
            :editionId="dlgMaterial.standardTeachingMaterialId"
            placeholder="请选择"
          />
        </el-form-item>
        <el-form-item label="" class="form-item">
          <el-button type="default" @click="dlgMaterial.visible = false">取消</el-button>
          <el-button type="primary" @click="handleAdd">确定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { Difficulty } from '@/config/enums/Difficulty'

import { questionList, questionRackUpAndDown } from '@/api/recordQuestion'
import {
  commonSubsectionList,
  commonTypeList,
} from '@/api/question'
import QuestionItem from './modules/question-item'
import Form from './modules/form'

export default {
  name: 'QuestionList',
  components: {
    'cmp-edition': Form.Edition,
    'cmp-teaching-material': Form.TeachingMaterial,

    QuestionItem
  },
  data () {
    return {
      loading: false,
      searchData: {
        questionStatus: 'TO_BE_PROCESSED',

        subsectionId: undefined, // 学段科目
        degreeOfDifficulty: '', // 难度
        questionType: '', // 题型
        dataStatus: '', // 状态（上下架）
        teachingMaterialId: undefined, // 教辅
        pageNumber: undefined, // 页码
      },
      page: {
        currentPage: 1,
        size: 20,
        total: 0
      },
      questionList: [],
      curQuestionIndex: null, // 当前题目序号
      preview: { // 原图预览
        visible: false,
        list: [],
      },

      subjectList: [], // 学科选项
      curSubject: null, // 当前选择学科
      difficultyOptions: Difficulty, // 难度选项
      typeOptions: [], // 题型选项
      editionGradeValue: undefined, // 当前教材版本: 出版社-学段id

      // 批量新增对话框 - 选择教辅
      dlgMaterial: {
        visible: false,
        standardTeachingMaterialId: undefined,
        teachingMaterialId: undefined
      }
    }
  },
  mounted () {
    this.loadSubjectList()
      .then(() => {
        // 设置默认科目
        const current = JSON.parse(localStorage.getItem('CURRENT.SUBJECT'))
        if (this.subjectList && this.subjectList.length) {
          if (current) {
            const section = this.subjectList.find(m => m.sort === current.sort)
            if (section) {
              const subject = section.subjects.find(m => m.id === current.id)
              return this.changeSubject(subject, section, true)
            }
          } else {
            const section = this.subjectList[0]
            this.changeSubject(section.subjects[0], section, true)
          }
        }
      })
  },
  filters: {
    disablePreview (record) {
      if (record.source && record.source.length) {
        const infoList = record.source[0].coordinateInfo
        if (infoList && infoList.length) {
          return false
        }
      }
      return true
    }
  },
  methods: {
    // 加载学科选项
    loadSubjectList () {
      return commonSubsectionList().then(res => {
        if (res.code === 0) {
          this.subjectList = res.data
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    // 更改学科（点击学科选择组件)
    handleSubjectCommand ({ item, section }) {
      this.changeSubject(item, section)
    },

    // 更改学科
    changeSubject (subject, section, isInit = false) {
      // isInit: 初始化，false: 表示用户点击切换学科，需清除教材版本和教辅选项
      if (subject) {
        const cur = Object.assign({
          sort: section.sort,
          subsection: section.subsection
        }, subject)

        localStorage.setItem('CURRENT.SUBJECT', JSON.stringify(cur))
        // if (isInit) {
        //   localStorage.removeItem('EG_ID')
        // }
        this.curSubject = cur

        // 清理查询条件
        Object.assign(this.searchData, {
          subsectionId: subject.id,
          // degreeOfDifficulty: '',
          questionType: '',
          // dataStatus: ''
          teachingMaterialId: undefined,
          pageNumber: undefined
        })
        this.handleSearch()

        this.loadTypeList(subject.id)
      }
    },

    // 题型选项
    loadTypeList (subjectId) {
      this.questionType = ''
      return commonTypeList(subjectId).then(res => {
        if (res.code === 0) {
          this.typeOptions = res.data
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    // 选择教辅
    changeTeachingMaterial () {
      this.handleSearch()
    },

    handleNav (routeName) {
      if (routeName === 'selectMaterial') {
        // 显示教材选择弹窗
        this.dlgMaterial.visible = true
      } else {
        this.$router.push({ name: routeName })
      }
    },

    handleAdd () {
      if (this.dlgMaterial.standardTeachingMaterialId && this.dlgMaterial.teachingMaterialId) {
        this.$router.push({
          name: 'QuestionAdd',
          query: {
            subjectId: this.curSubject.id,
            editionId: this.dlgMaterial.standardTeachingMaterialId,
            materialId: this.dlgMaterial.teachingMaterialId
          }
        })
      }
    },

    // 选择版本
    changeEdition (d) {
      if (!d) {
        this.handleSearch()
      }
    },

    // 搜索、过滤
    handleSearch () {
      this.page.currentPage = 1
      this.loadData()
    },

    // 选中列表项 - 高亮
    handleCurrentChange (currentPage) {
      this.page.currentPage = currentPage
      this.loadData()
    },

    // 上下架
    handleRackUp (record, status) {
      const msg = status ? '确认上架' : '确认下架'
      this.$confirm(msg).then(() => {
        this.loading = true
        questionRackUpAndDown(record.questionId, status).then(res => {
          if (res.code === 0) {
            this.$message.success('完成')
            this.loadData(true)
          } else {
            this.$message.error(res.msg)
          }
        }).finally(() => {
          this.loading = false
        })
      }).catch(() => {})
    },

    // 原图预览
    handlePreview (question, materialInfo) {
      const coordinateInfo = materialInfo.coordinateInfo
      if (coordinateInfo && coordinateInfo.length) {
        const infoMap = {}
        coordinateInfo.forEach(m => {
          if (!infoMap[m.page]) {
            infoMap[m.page] = {
              page: m.page,
              bg: m.image,
              // coordinate: [m.coordinate]
              coordinate: m.coordinate
            }
          } else {
            infoMap[m.page].coordinate.push(m.coordinate)
          }
        })

        this.preview.data = {
          questionId: question.questionId,
          teachingMaterialId: materialInfo.teachingMaterialId
        }
        this.preview.list = Object.values(infoMap)
        this.preview.visible = true
      }

      // ['https://image-question-bank.hnzypj.com/teaching-material/1/2.png']
    },

    // 画坐标
    drawCoordinates (teachingMaterialId, questionId) {
      this.$router.push({
        name: 'DrawCoordinates',
        params: { teachingMaterialId },
        query: { questionId }
      })
    },

    /**
     * 编辑/预览操作
     * @param params
     * @param {String} params.type 操作类型：画坐标/预览/编辑
     * @param {Object} params.question 题目完整数据
     * @param {Object} params.data 数据
     */
    handleOperate ({ type, question, data }) {
      if (type === 'draw') {
        return this.drawCoordinates(data, question.questionId)
      }
      if (type === 'preview') {
        return this.handlePreview(question, data)
      }
      if (type === 'edit') {
        this.$router.push({
          name: 'QuestionEdit',
          query: {
            id: question.questionId,
            teachingMaterialId: data
          }
        })
      }
    },

    // refresh: 刷新列表同保持滚动条位置，由于数学公式渲染可能有一定位移
    loadData (refresh) {
      const params = {
        size: this.page.size,
        currentPage: this.page.currentPage
      }
      Object.keys(this.searchData).forEach(key => {
        const val = this.searchData[key]
        if (val) { params[key] = val }
      })

      this.loading = true
      return questionList(params).finally(() => {
        if (!refresh) {
          const elWrapper = document.querySelector('.el_main')
          if (elWrapper) elWrapper.scrollTop = 0
        }
        this.curQuestionIndex = null
        this.questionList = []
        this.loading = false
      }).then(res => {
        if (res.code === 0) {
          const data = res.data
          this.questionList = data.list
          this.page.total = data.total
        } else {
          this.$message.error(res.msg)
        }
      })
    },
  }
}
</script>

<style lang="scss">
.ui-panel {
  margin: 20px;
  padding: 10px 30px;
  background: #fff;

  &-title {
    font-size: 22px;
    line-height: 30px;
  }

  .el-radio__input {
    display: none;
  }
}

.subject {
  &-group {
    margin: 0 10px;
    width: 120px;
    //display: flex;
    //justify-content: space-around;
    //flex-wrap: wrap;
  }
  &-head {
    width: 100%;
    margin: 5px 0;
    color: #ccc;
    clear: both;
  }
  &-list {
    margin: 0 -5px;
  }
  &-list > &-item {
    box-sizing: border-box;
    float: left;
    font-size: 12px;
    line-height: 28px;
    padding: 0 5px;
    width: 33%;
    color: #18C2C2;
  }
}

.searchForm {
  padding-top: 10px;

  .el-form-item {
    margin-bottom: 8px;
  }
  .form-item-inline {
    margin-right: 2em;
  }

  .search-radio {
    margin-top: 8px;
    margin-bottom: 8px;
  }
}

.ui-pagination {
  text-align: right;
}
.ui-space {
  > .header-btn {
    margin-left: 10px;

    button {
      padding-bottom: 8px;
      padding-top: 8px;
    }
  }
}

// 题目操作按钮
.operate {
  display: flex;
  justify-content: right;
  padding: 3px 10px;
  min-width: 300px;

  // 下拉按钮
  &-group.el-button {
    margin-left: 10px;
  }

  .hd {
    font-size: 12px;
    line-height: 26px;
    height: 26px;
    max-width: 300px;
    margin-right: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  li.el-dropdown-menu__item {
    margin: 0 5px;
    padding: 0 5px;
    color: #18C2C2;
  }
}

.preview-footer {
  margin-top: 20px;
  text-align: center;
}
</style>
