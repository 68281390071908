// 年级
export const Grade = [
  {
    level: 1,
    id: 'FIRST_GRADE',
    value: '一年级'
  },
  {
    level: 1,
    id: 'SECOND_GRADE',
    value: '二年级'
  },
  {
    level: 1,
    id: 'THIRD_GRADE',
    value: '三年级'
  },
  {
    level: 1,
    id: 'FOURTH_GRADE',
    value: '四年级'
  },
  {
    level: 1,
    id: 'FIFTH_GRADE',
    value: '五年级'
  },
  {
    level: 1,
    id: 'SIXTH_GRADE',
    value: '六年级'
  },
  {
    level: 2,
    id: 'SEVEN_GRADE',
    value: '七年级'
  },
  {
    level: 2,
    id: 'EIGHTH_GRADE',
    value: '八年级'
  },
  {
    level: 2,
    id: 'NINTH_GRADE',
    value: '九年级'
  },
  {
    level: 3,
    id: 'HIGH_FIRST',
    value: '高一'
  },
  {
    level: 3,
    id: 'HIGH_SECOND',
    value: '高二'
  },
  {
    level: 3,
    id: 'HIGH_THIRD',
    value: '高三'
  }
]
