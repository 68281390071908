<template>
  <div class="ui-setting">
    <el-drawer
      title="打标签"
      destroy-on-close
      :size="360"
      :wrapperClosable="false"
      :visible.sync="visible"
    >
      <el-form size="small" class="setting-form" label-width="6em" label-position="top">
        <el-form-item label="选择知识点" class="form-item">
          <el-button type="primary" plain @click="showKnowledgeOptions">选择知识点</el-button>
          <div v-if="knowledgeData.length" class="result">
            <div class="result-label">已选：</div>
            <ul class="result-list">
              <li v-for="item in knowledgeData" :key="item.id">{{ item.title }}</li>
            </ul>
          </div>
        </el-form-item>

        <el-form-item label="选择章节目录" class="form-item" label-width="100%" v-if="!hideChapters">
          <el-button type="primary" plain @click="showChapterOptions">选择章节目录</el-button>
          <div v-if="chapterData.length" class="result">
            <div class="result-label">已选：</div>
            <ul class="result-list">
              <li v-for="item in chapterData" :key="item.id">{{ item.name }}</li>
            </ul>
          </div>
        </el-form-item>

        <el-form-item label="选择核心素养（非必需）" class="form-item">
          <el-select v-model="formData.keyAccomplishment" placeholder="请选择" multiple>
            <el-option
              v-for="item in keyAccomplishmentOptions"
              :key="item.id"
              :label="item.title"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>

        <div class="form-footer">
          <el-button type="default" @click="close">取消</el-button>
          <el-button type="primary" @click="submit">确认</el-button>
        </div>
      </el-form>
    </el-drawer>

    <!-- 知识点 -->
    <el-dialog
      :visible.sync="knowledgeVisible"
      destroy-on-close
      width="500px"
    >
      <div class="chapt-title">
        {{ searchData.editionName }} - {{ searchData.chapterName }}
      </div>
      <el-tree
        ref="KnowledgeTree"
        :props="{ children: 'children', label: 'title', value: 'id' }"
        :data="knowledgeOptions"
        :default-expanded-keys="formData.knowledgeIds"
        class="dlg-main"
        check-strictly
        node-key="id"
        show-checkbox
      />
      <div class="dlg-footer">
        <el-button size="small" @click="closeKnowledgeOptions">取消</el-button>
        <el-button size="small" type="primary" @click="submitKnowledgeDate">确定</el-button>
      </div>
    </el-dialog>

    <!-- 章节 -->
    <el-dialog
      :visible.sync="chapterVisible"
      destroy-on-close
      width="500px"
    >
      <el-tree
        ref="ChapterTree"
        :props="{ label: 'name', value: 'id' }"
        :data="chapterOptions"
        :default-expanded-keys="formData.chapterIds"
        @check-change="changeChapter"
        class="dlg-main"
        check-strictly
        node-key="id"
        show-checkbox
      />
      <div class="dlg-footer">
        <el-button size="small" @click="closeChapterOptions">取消</el-button>
        <el-button size="small" type="primary" @click="submitChapterData">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import ElDrawer from 'element-ui/packages/drawer'
import { getChapterList } from '@/api/question-bank'
import { getKeyAccomplishmentList } from '@/api/keyAccomplishment'
import { getKnowledgePointList } from '@/api/knowledge'

// /**
//  * 树遍历
//  * @param {Array<Object>} treeList 遍历对象
//  * @param {Function} action 节点处理函数
//  * @returns {*}
//  * @desc action 函数返回 true 表示终止遍历
//  */
// function treeTraversal (treeList, callback) {
// }

/**
 * 查找节点
 */
function findTreeNode (list, val, keyName = 'id') {
  let node
  list.findIndex(item => {
    if (item[keyName] === val) {
      node = item
      return true
    }
    if (item.children) {
      const n = findTreeNode(item.children, val, keyName)
      if (n) {
        node = n
        return true
      }
    }
  })
  return node
}

export default {
  name: 'Setting',
  components: {
    ElDrawer,
  },
  props: {
    hideChapters: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loading: false,
      visible: false,
      question: null,
      formData: {
        knowledgeIds: [],
        chapterIds: [],
        keyAccomplishment: [],
      },

      knowledgeData: [], // 选中节点
      knowledgeOptions: [], // 选项
      knowledgeVisible: false,

      chapterData: [],
      chapterOptions: [],
      chapterVisible: false,

      keyAccomplishmentOptions: [],
      searchData: this.$store.state.branchImport.searchData
    }
  },
  methods: {
    // // <el-tree props="{ disabled: filterDisabled }"/>
    // filterDisabled (data, node) {
    //   return false
    //   // console.log(data, node)
    //   // return !data.children || data.children.length > 0
    // },

    //#region - 知识点管理
    closeKnowledgeOptions () {
      this.knowledgeVisible = false
    },

    showKnowledgeOptions () {
      if (!this.question.subsectionId) {
        return this.$message.error('请选择学段')
      }
      this.knowledgeVisible = true
      this.$nextTick(() => {
        this.$refs.KnowledgeTree.setCheckedKeys(this.formData.knowledgeIds)
      })
    },

    submitKnowledgeDate () {
      const nodes = this.$refs.KnowledgeTree.getCheckedNodes()
      this.knowledgeData = nodes
      this.formData.knowledgeIds = nodes.map(m => m.id)
      this.closeKnowledgeOptions()
    },

    loadKnowledgePointList (subjectId) {
      return getKnowledgePointList(subjectId).then(res => {
        if (res.code === 0) {
          const list = this.disabledKnowData(res.data)
          this.knowledgeOptions = list
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    //#endregion

    // 处理知识点数据,非最后一层级都不可选中
    disabledKnowData (list) {
      list.forEach(item => {
        if (item.children && item.children.length > 0) {
          item.disabled = true
          this.disabledKnowData(item.children)
        }
      })
      return list
    },

    //#region - 章节管理
    closeChapterOptions () {
      this.chapterVisible = false
    },

    showChapterOptions () {
      // if (!this.question.subsectionId) {
      //   return this.$message.error('请选择学段')
      // }
      if (!this.question.standardTeachingMaterialId) {
        return this.$message.error('请选择教材版本')
      }
      this.chapterVisible = true
      this.$nextTick(() => {
        this.$refs.ChapterTree.setCheckedKeys(this.formData.chapterIds)
      })
    },

    changeChapter (nodes) {
      if (this.$refs.ChapterTree.getCheckedNodes().length > 1) {
        this.$refs.ChapterTree.setCheckedKeys([nodes.id])
      }
    },

    submitChapterData () {
      const nodes = this.$refs.ChapterTree.getCheckedNodes()
      this.chapterData = nodes
      this.formData.chapterIds = nodes.map(m => m.id)
      this.closeChapterOptions()
    },

    loadChapterList (materialId) {
      return getChapterList(materialId).then(res => {
        if (res.code === 0) {
          const options = res.data.list
          this.chapterOptions = options
          this.chapterList = res.data.chapters // flat数组
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    //#endregion

    // 加载核心素养列表
    loadKeyAccomplishmentList (subsectionId) {
      return getKeyAccomplishmentList(subsectionId).then(res => {
        if (res.code === 0) {
          this.keyAccomplishmentOptions = res.data
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    submit () {
      this.$emit('submit', this.formData)
      this.close()
    },

    show (options) {
      // options.knowledgeIds = [440559, 440578]
      // options.chapterIds = [111773]
      this.question = options
      // * 初始化表单数据
      this.formData.knowledgeIds = options.knowledge ? options.knowledge.map(m => m.knowledgeId) : []
      this.formData.chapterIds = options.chapter ? options.chapter.map(m => m.chapterId) : []
      this.formData.keyAccomplishment = options.keyAccomplishment ? options.keyAccomplishment.map(m => m.keyAccomplishmentId) : []
      // 1. 知识点
      if (!this.question.subsectionId) {
        return this.$message.error('请选择学段')
      }
      this.loadKnowledgePointList(this.searchData.subjectId).then(() => {
        const nodes = []
        this.formData.knowledgeIds.forEach(val => {
          const node = findTreeNode(this.knowledgeOptions, val)
          if (node) {
            nodes.push(node)
          } else {
            this.$message.error(`知识点ID：${val} 未找到匹配项`)
          }
        })
        this.knowledgeData = nodes
      })

      // 2. 章节
      if (!this.question.standardTeachingMaterialId) {
        return this.$message.error('请选择教材版本')
      }
      this.loadChapterList(this.question.standardTeachingMaterialId).then(() => {
        const nodes = []
        this.formData.chapterIds.forEach(val => {
          const node = findTreeNode(this.chapterOptions, val)
          if (node) {
            nodes.push(node)
          } else {
            this.$message.error(`章节ID：${val} 未找到匹配项`)
          }
        })
        this.chapterData = nodes
      })

      // 3. 核心素养
      this.loadKeyAccomplishmentList(this.question.subsectionId)

      this.visible = true
    },

    close (e) {
      this.visible = false
      this.$emit('close', e)
    }
  }
}
</script>

<style lang="scss" scoped>
.setting-form /deep/ {
  position: relative;
  height: calc(100vh - 150px);
  min-height: 350px;
  margin: 20px 50px;

  .form-footer {
    position: absolute;
    bottom: 0;
    width: 100%;

    display: flex;

    .el-button {
      flex: 1;
      margin: 0 10px;
    }
  }

  .el-form-item__label {
    color: #000;
  }
}

.dlg-main {
  max-height: calc(60vh);
  overflow: auto;

  /deep/ .el-tree-node__children {
    overflow: visible;
  }
}
.dlg-footer {
  text-align: right;
  margin: 10px 0 -10px;
}

.result {
  &-label {
    float: left;
  }

  &-list {
    margin-left: 50px;
    // line height: 32px;
    padding: 4px 0;
    line-height: 24px;
    color: #999;
  }
}
.chapt-title{
  margin-bottom: 20px;
  color: #18C2C2;
  font-size: 15px;
  font-weight: 600;
}
</style>
