/* eslint-disable */
import { Shape } from './Shape'
import { Polygon } from './Polygon'

export class Rect extends Shape {
  constructor (props) {
    super(props)
    this.startX = props.x
    this.startY = props.y
    this.x = props.x
    this.y = props.y
    this.width = props.width
    this.height = props.height
  }

  drawAnnotation (ctx) {
    let p = this.getKnobs()[0]
    let textWidth = ctx.measureText(this.tipText).width
    let dx = (this.width - textWidth) / 2
    ctx.fillStyle = this.tipTextStyle
    ctx.font = this.font
    let textX = p.x + dx
    let textY = this.computedTextY(p.y)
    ctx.fillText(this.tipText, textX, textY)
  }

  move (props) {
    this.x += props.dx
    this.y += props.dy
    this.buildPath()
  }

  scale (canvasScale) {
    this.startX *= canvasScale
    this.startY *= canvasScale
    this.x *= canvasScale
    this.y *= canvasScale
    this.width *= canvasScale
    this.height *= canvasScale
    this.buildPath()
  }

  updateDraw(point) {
    this.width = Math.abs(point.x - this.startX)
    this.height = Math.abs(point.y - this.startY)
    this.x = Math.min(this.startX, point.x)
    this.y = Math.min(this.startY, point.y)
  }

  getKnobs() {
    return [
      {x: this.x, y: this.y},
      {x: this.x, y: this.y + this.height},
      {x: this.x + this.width, y: this.y + this.height},
      {x: this.x + this.width, y: this.y}
    ]
  }

  buildPath() {
    this.pathStroke = new Path2D()
    this.pathFill = new Path2D()
    this.pathStroke.rect(this.x, this.y, this.width, this.height)
    this.pathFill.rect(this.x, this.y, this.width, this.height)
    this.buildKnobs()
  }

  isVisible() {
    // console.log('rect isVisible: ',  this.width > 3 && this.height > 3)
    return this.width > 3 && this.height > 3
  }

  toPolygon() {
    let points = this.getKnobs()
    // let {tipText, assignType, scaleSwitch, isOrderly, knobSize, knobTol2} = this
    // return new Polygon({
    //     tipText,
    //     assignType,
    //     scaleSwitch,
    //     isOrderly,
    //     points,
    //     knobSize,
    //     knobTol2,
    //     ctx: this.ctx
    // })
    return new Polygon(Object.assign({ points }, this))
  }

  pack(scale) {
    scale = scale || 1
    return [
      {
        x: this.x / scale,
        y: this.y / scale,
        width: this.width / scale,
        height: this.height / scale
      }
    ]
  }
}
