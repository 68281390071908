/* eslint-disable */
const KNOB_TOL2 = 5 * 5
const KNOB_SIZE = 5

export class Shape {
  constructor (props) {
    if (!props.ctx) {
      throw 'no context'
    }
    this.ctx = props.ctx
    this.tipText = props.tipText || '' // 标注提示文字
    this.assignType = props.assignType // 指定输出的type
    this.scaleSwitch = Boolean(props.scaleSwitch) // 扩大缩小开关
    this.isOrderly = props.isOrderly || false // 有序/无序
    this.knobSize = props.knobSize || KNOB_SIZE // 节点查询大小
    this.knobTol2 = props.knobSize ? Math.pow(props.knobSize, 2) : KNOB_SIZE // 节点查询大小
    this.knobShowAlways = false // 是否一直显示节点
    this.isActive = props.isActive !== false // 是否在激活状态
    this.autoComplete = true // 自动完成(即鼠标抬起后当前shape绘画完成，false则代表需手动触发或达到一定条件后当前shape才绘画结束)
    this.moveAble = props.moveAble !== false // shape是否可移动
    this.pathStroke = new Path2D()
    this.pathFill = new Path2D()
    this.fillStyle = 'rgba(128,255,0,0.3)'
    this.pathAnnotation = new Path2D()
    this.pathKnobs = []
    this.knobsFillStyle = 'red'
    this.font = '600 12px serif'
    this.textDy = 5
    this.lineWidth = 2
    this.lineJoin = 'miter'
    this.isHighlighted = false

    this.$data = props.$data || {}
    // this.strokeStyle = '#ff0000'
    // this.tipTextStyle = 'blue'
    this.setStyleType(this.isActive)
  }

  setStyleType (isActive) {
    if (isActive) {
      this.tipTextStyle = 'blue'
      this.strokeStyle = '#ff0000'
    } else {
      this.tipTextStyle = '#ff0000'
      this.strokeStyle = '#00b87b'
    }
  }

  setHighlight (on) {
    this.isHighlighted = Boolean(on)
    this.buildPath()
  }

  setActive (on) {
    this.isActive = Boolean(on)
    this.setStyleType(this.isActive)
    if (this.isActive) {
      this.moveAble = true
    }
    this.buildPath()
  }

  isPointOnKnob (point) {
    const points = this.getKnobs()
    let dx2, dy2
    for (let i = 0; i < points.length; i++) {
      dx2 = (points[i].x - point.x) * (points[i].x - point.x)
      dy2 = (points[i].y - point.y) * (points[i].y - point.y)
      if (dx2 + dy2 < KNOB_TOL2) {
        // console.log('highlight')
        return i
      }
    }
    return -1
  }

  isPointInPath (point) {
    return this.ctx.isPointInPath(this.pathStroke, point.x, point.y)
  }

  /**
   *
   * @param {*} ctx
   */
  draw (ctx) {
    ctx.strokeStyle = this.strokeStyle
    ctx.lineWidth = this.lineWidth
    ctx.stroke(this.pathStroke)
    this.buildKnobs()
    this.drawAnnotation(ctx)
    if (this.isHighlighted) {
      ctx.fillStyle = this.fillStyle
      ctx.fill(this.pathFill)
      ctx.fillStyle = this.knobsFillStyle
      this.pathKnobs.forEach(knob => {
        ctx.fill(knob)
      })
    }
  }

  move () {
    throw 'move() is not implemented'
  }

  scale () {
    throw 'scale() is not implemented'
  }

  buildPath () {
    throw 'buildPath() is not implemented'
  }

  isVisible () {
    return true
  }

  canCompleteDraw () {
    return true
  }

  getKnobs () {
    throw 'getKnobs() is not implemented'
  }

  buildKnobs () {
    this.pathKnobs = this.getKnobs().map(p => {
      const path = new Path2D()
      path.arc(p.x, p.y, this.knobSize, 0, Math.PI * 2)
      this.knobShowAlways && this.ctx.stroke(path)
      return path
    })
  }

  pack () {
    throw 'pack() is not implemented'
  }

  static restore () {
    throw 'restore() is not implemented'
  }

  computedTextY (centerY) {
    if (centerY < this.textDy * 2) {
      if (centerY < 0) {
        return this.textDy * 1.5
      } else {
        return centerY + this.textDy * 1.5
      }
    } else {
      return centerY - this.textDy
    }
  }
}
