import Layout from '@/views/questionBank/modules/Layout'
import QuestionList from '@/views/questionBank/QuestionList.vue'
import QuestionAdd from '@/views/questionBank/QuestionAdd.vue'
import QuestionEdit from '@/views/questionBank/QuestionEdit.vue'

export default {
  path: '/question',
  name: 'Question',
  redirect: '/question/list',
  component: Layout,
  meta: { type: 'teachingMaterial' },
  children: [
    {
      path: '/question/list/:page([1-9]\\d*)?',
      name: 'QuestionList',
      component: QuestionList
    },
    {
      path: '/question/add',
      name: 'QuestionAdd',
      component: QuestionAdd
    },
    {
      path: '/question/edit',
      name: 'QuestionEdit',
      component: QuestionEdit
    }
  ]
}
