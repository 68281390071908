import request from '@/utils/request'

/**
 * 学段、科目选项列表
 */
export function commonSubsectionList () {
  return request({
    url: '/question/common/subsection/list',
    method: 'GET'
  })
}

/**
 * 题型选项列表
 * @param {Number} subsectionSubjectId 学段科目ID
 */
export function commonTypeList (subsectionSubjectId) {
  return request({
    url: '/question/common/type/list',
    method: 'GET',
    params: {
      subsectionSubjectId
    }
  })
}

/**
 * 教材版本选项列表
 * @param {Number} subsectionSubjectId 学段科目ID
 * @param {String} subject 学科ID - 非必填，基础性作业版本列表查询是使用，以精简选项
 */
export function commonMaterialList (subsectionSubjectId, subject) {
  return request({
    url: '/question/common/standard/material/list',
    method: 'GET',
    params: {
      subsectionSubjectId,
      subject
    }
  })
}
