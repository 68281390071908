/* eslint-disable */
import { Rect } from './Shape/Rect'

export default class CanvasController {
  constructor (props) {
    this.regionCanvas = props.regionCanvas
    this.imageCanvas = props.imageCanvas
    this.$region = props.$region
    this.$image = props.$image
    this.$canvasLayer = props.$canvasLayer
    this.$displayArea = props.$displayArea

    this.rotateAngle = 0
    this.scaleIndex = 7
    this.scaleRange = [
      0.25,
      0.33,
      0.5,
      0.67,
      0.75,
      0.8,
      0.9,
      1.0,
      1.1,
      1.25,
      1.5,
      1.75,
      2.0,
      2.5,
      3.0,
      4.0,
      5.0
    ]

    this.scaleOrigin = 1.0
    this.currentScale = 1.0
    this.lastScale = 1.0
    this.naturalWidth = 0.0
    this.naturalHeight = 0.0
  }

  init () {
    this.rotateAngle = 0
    this.scaleOrigin = 1.0
    this.currentScale = 1.0
    this.scaleIndex = 7
    this.lastScale = 1.0
    this.naturalWidth = 0.0
    this.naturalHeight = 0.0
  }

  pack () {
    return this.regionCanvas.pack(this.scaleOrigin * this.currentScale)
  }

  /**
   * 删除选中的形状
   * @param {points} props 鼠标点击的位置
   * 此处不用在计算滚动条的位置，offect一直是相对DOM左上角的位置
   */
  removeShape (props) {
    this.regionCanvas.removeShape(props)
  }

  /**
   * 设置绘制的形状
   * @param {shape} props 在canvas绘制的形状
   */
  setDrawingShape (props) {
    this.regionCanvas.setDrawingShape(props)
  }

  /**
   * 使图片能够合适的显示在可是区域上
   * @param {number} displayWidth 显示区域宽度
   * @param {number} displayHeight 显示区域高度
   */
  fitDisplayArea (displayWidth, displayHeight, mode) {
    let imageWidth = 0
    let imageHeight = 0

    if (this.rotateAngle === 90 || this.rotateAngle === 270) {
      imageWidth = this.naturalHeight
      imageHeight = this.naturalWidth
    } else if (this.rotateAngle === 0 || this.rotateAngle === 180) {
      imageWidth = this.naturalWidth
      imageHeight = this.naturalHeight
    } else {
      throw 'illegal angle'
    }
    let scaleWidth = 1.0
    let scaleHeight = 1.0

    if (mode === 'ocr') {
      scaleWidth = displayWidth / imageWidth
      imageWidth = displayWidth
      imageHeight = imageHeight * scaleWidth
    } else if (imageWidth > displayWidth) {
      // resize image to match the panel width
      scaleWidth = displayWidth / imageWidth
      imageWidth = displayWidth
      imageHeight = imageHeight * scaleWidth
    }

    if (imageHeight > displayHeight) {
      // resize further image if its height is larger than the image panel
      scaleHeight = displayHeight / imageHeight
      imageHeight = displayHeight
      imageWidth = imageWidth * scaleHeight
    }

    this.scaleOrigin = scaleHeight * scaleWidth
  }

  /**
   * loadImage from disk
   * @param {object} props
   * props.src: image.src
   */
  loadImage (props) {
    return new Promise((resolve, reject) => {
      const image = new Image()
      const imageFilename = props.src
      const { displayWidth, displayHeight, mode } = props

      image.onerror = err => {
        console.error('Error loading image !')
        reject(err)
      }
      image.onload = () => {
        this.init()
        let imageWidth = image.naturalWidth
        let imageHeight = image.naturalHeight

        this.naturalWidth = imageWidth
        this.naturalHeight = imageHeight

        this.fitDisplayArea(displayWidth, displayHeight, mode)

        if (this.rotateAngle === 90 || this.rotateAngle === 270) {
          imageWidth = this.naturalHeight * this.scaleOrigin
          imageHeight = this.naturalWidth * this.scaleOrigin
        } else if (this.rotateAngle === 0 || this.rotateAngle === 180) {
          imageWidth = this.naturalWidth * this.scaleOrigin
          imageHeight = this.naturalHeight * this.scaleOrigin
        } else {
          throw new Error('illegal angle')
        }

        this.setAllCanvasSize(imageWidth, imageHeight, 1.0)
        this.imageCanvas.init({
          image,
          imageFilename
        })
        this.imageCanvas.draw(imageWidth, imageHeight, this.rotateAngle, 1.0)

        resolve()
      }
      image.src = imageFilename
    })
  }

  // listen the wheel to zoom image and region
  wheel (props) {
    const { deltaY, offsetX, offsetY } = props
    if (deltaY < 0) {
      if (this.scaleIndex >= this.scaleRange.length - 1) {
        return
      }
      this.scaleIndex += 1
    } else if (deltaY > 0) {
      if (this.scaleIndex <= 0) {
        return
      }
      this.scaleIndex -= 1
    }

    this.currentScale = this.scaleRange[this.scaleIndex]

    const { $displayArea } = this
    const { scrollLeft, scrollTop } = $displayArea
    const canvasScale = this.currentScale / this.lastScale

    this.zoom({
      canvasScale
    })

    const { regionCanvas } = this

    let scrollLeftDelta = offsetX * canvasScale - offsetX
    let scrollTopDelta = offsetY * canvasScale - offsetY

    $displayArea.scrollLeft += scrollLeftDelta
    $displayArea.scrollTop += scrollTopDelta

    scrollLeftDelta = $displayArea.scrollLeft - scrollLeft
    scrollTopDelta = $displayArea.scrollTop - scrollTop

    const point = {
      x: offsetX + scrollLeftDelta,
      y: offsetY + scrollTopDelta
    }

    regionCanvas.shapeList.forEach(shape => {
      if (shape.isHighlighted === true) {
        shape.setHighlight(false)
      }
    })
    for (let i = 0; i < regionCanvas.shapeList.length; i++) {
      const shape = regionCanvas.shapeList[i]
      if (shape.isPointInPath(point) || shape.isPointOnKnob(point) !== -1) {
        shape.setHighlight(true)
        break
      }
    }
    regionCanvas.clear()
    regionCanvas.render()

    this.lastScale = this.currentScale
  }

  reDrawWithRotateAndZoom (oldW, oldH, scale) {
    if (this.rotateAngle === 90 || this.rotateAngle === 270) {
      this.setAllCanvasSize(oldH, oldW, scale)
    } else if (this.rotateAngle === 0 || this.rotateAngle === 180) {
      this.setAllCanvasSize(oldW, oldH, scale)
    } else {
      throw 'illegal angle'
    }

    // this.setAllCanvasScale(scale)
    this.imageCanvas.draw(oldW, oldH, this.rotateAngle, scale)
  }

  zoom (props) {
    const { regionCanvas } = this
    const { canvasScale } = props
    // 缩放之前的高度和宽度
    const lastW = this.naturalWidth * this.scaleOrigin * this.lastScale
    const lastH = this.naturalHeight * this.scaleOrigin * this.lastScale

    this.reDrawWithRotateAndZoom(lastW, lastH, canvasScale)

    if (regionCanvas.shapeList.length > 0) {
      regionCanvas.ctx.clearRect(0, 0, lastW, lastH)
      regionCanvas.shapeList.forEach(shape => {
        if (shape) {
          shape.scale(canvasScale)
        } else {
          throw 'shape is not exist!'
        }
      })
    }
    if (regionCanvas.drawingShapeObject) {
      regionCanvas.drawingShapeObject.scale(canvasScale)
    }
  }

  /**
   * 设置两个canvas的大小和重新绘制边框
   * @param {number} w lastWidth
   * @param {number} h lastHeight
   * @param {number} scale
   */
  setAllCanvasSize (w, h, scale) {
    const { $region, $image, $canvasLayer } = this
    w *= scale
    h *= scale

    $region.width = w
    $region.height = h

    $image.width = w
    $image.height = h

    $canvasLayer.style.width = w + 'px'
    $canvasLayer.style.height = h + 'px'
  }

  /**
   * @param {number} canvasScale 缩放两个canvas，可能无用
   */
  // setAllCanvasScale(canvasScale) {
  //     let {regionCanvas, imageCanvas} = this
  //     regionCanvas.ctx.scale(canvasScale, canvasScale)
  //     imageCanvas.ctx.scale(canvasScale, canvasScale)
  // }

  /**
   * 鼠标右键按下时，可以拖动图片
   * @param {Object} props movement
   * props.dx: movementX
   * props.dy: movementY
   */
  rightMouseMove (props) {
    this.$displayArea.scrollLeft -= props.dx
    this.$displayArea.scrollTop -= props.dy
  }

  clearBeforeRotate () {
    let imageWidth, imageHeight
    if (this.rotateAngle === 90 || this.rotateAngle === 270) {
      imageWidth = this.naturalHeight * this.scaleOrigin * this.currentScale
      imageHeight = this.naturalWidth * this.scaleOrigin * this.currentScale
    } else if (this.rotateAngle === 0 || this.rotateAngle === 180) {
      imageWidth = this.naturalWidth * this.scaleOrigin * this.currentScale
      imageHeight = this.naturalHeight * this.scaleOrigin * this.currentScale
    } else {
      throw 'illegal angle'
    }
    this.imageCanvas.ctx.clearRect(0, 0, imageWidth, imageHeight)

    this.regionCanvas.clear()
    this.regionCanvas.deleteAll()
  }

  rotate (angle, displayWidth, displayHeight) {
    this.clearBeforeRotate()

    this.rotateAngle = angle

    this.scaleOrigin = 1.0
    this.currentScale = 1.0
    this.lastScale = 1.0
    this.scaleIndex = 7

    this.fitDisplayArea(displayWidth, displayHeight)

    // 原始的未加旋转的 宽度 和 高度
    const oldW = this.naturalWidth * this.scaleOrigin
    const oldH = this.naturalHeight * this.scaleOrigin

    this.reDrawWithRotateAndZoom(oldW, oldH, 1.0)
  }

  // 将shape类型rect转换为polygon
  rectToPoly (annotationMap) {
    this.regionCanvas.shapeList.forEach((ele, index) => {
      if (ele instanceof Rect) {
        // console.log('rect to Poly')
        const type = this.regionCanvas.typeList[index]
        const polyShape = ele.toPolygon(ele.tipText, type)
        this.regionCanvas.shapeList[index] = polyShape
        this.regionCanvas.shapeListName[index] = 'polygon'
        polyShape.buildPath()
      }
    })
  }
}
/* eslint-disable */
