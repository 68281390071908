<template>
  <el-rate
    class="ui-difficulty-picker"
    v-bind="$attrs"
    v-model="rateData"
    :colors="colors"
    :texts="texts"
    @change="handleChange"
  />
</template>

<script>
import { Difficulty } from '@/config/enums/Difficulty'
import { Rate } from 'element-ui'

// const DifficultyData = {}
// const defaultTexts = []
// Difficulty.forEach((m, idx) => {
//   defaultTexts.push(m.label)
//   DifficultyData[m.value] = Object.assign({ index: idx + 1 }, m)
// })

export default {
  name: 'DifficultyPicker',
  components: {
    'el-rate': Rate,
  },
  props: {
    value: {
      type: String,
      default: undefined,
    },
    colors: {
      type: Array,
      default: () => ['#18C2C2', '#18C2C2', '#18C2C2']
    },
    texts: {
      type: Array,
      default: () => Difficulty.map(m => m.label)
    }
  },
  data () {
    return {
      rateData: 0
    }
  },
  watch: {
    value: {
      immediate: true,
      handler (value) {
        const idx = Difficulty.findIndex(m => m.value === value)
        this.rateData = idx + 1
      }
    }
  },
  methods: {
    handleChange (val) {
      const data = val > 0 ? Difficulty[val - 1] : null
      const value = data ? data.value : undefined
      this.$emit('input', value)
      this.$emit('change', value, data)
    },
  }
}
</script>

<style>
.ui-difficulty-picker {
  display: inline-block;
}
</style>
