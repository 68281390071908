import Quill from 'quill'

const Embed = Quill.import('blots/embed')

export class JaxBlot extends Embed {
  static blotName = 'jax'
  static tagName = 'mjx-container'
  static className = 'MathJax'

  static create (value) {
    // console.log('=== jaxBolt create', value)
    const node = super.create(value)
    node.innerHTML = value.innerHTML
    node.setAttribute('quill', 'jax')
    node.setAttribute('jax', 'SVG')
    node.dataset.latex = value.dataset.latex || ' '
    return node
  }

  static formats (node) {
    return node.dataset.latex || ' '
  }

  static value (node) {
    // console.log('=== jaxBolt value', node)
    return node
  }

  // format (name, value) {
  //   console.log('format', name, value)
  //   if (['alt', 'src', 'width', 'height'].includes(name)) {
  //     this.domNode.setAttribute(name, value);
  //   } else {
  //     super.format(name, value);
  //   }
  // }
}
