<template>
  <div class="ui-gallery-container">
    <SectionPicker ref="Preview" class="preview" v-if="previewData" :value="previewData" disabled locked />

    <template v-if="list.length > 1">
      <el-button
        type="text"
        class="ui-gallery-left"
        icon="el-icon-arrow-left"
        @click="handleSwipe('left')"
        :disabled="innerIndex < 1"
      />
      <el-button
        type="text"
        class="ui-gallery-right"
        icon="el-icon-arrow-right"
        @click="handleSwipe('right')"
        :disabled="innerIndex >= list.length - 1"
      />
    </template>
  </div>
</template>

<script>
import SectionPicker from '@/components/SectionPicker'

export default {
  components: {
    SectionPicker
  },
  props: {
    index: {
      type: Number,
      default: 0
    },
    list: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      innerIndex: 0
    }
  },
  watch: {
    index: {
      immediate: true,
      handler (val) {
        this.innerIndex = val
      }
    }
  },
  computed: {
    previewData () {
      return this.getPreviewData(this.list, this.innerIndex)
    }
  },
  methods: {
    getPreviewData (list, index) {
      const len = list.length
      if (!len) return null
      const val = list[index < len ? index : len]
      return typeof val === 'string' ? { bg: val } : val
    },

    handleSwipe (direct = 'right') {
      const len = this.list.length
      const idx = (direct === 'left' ? -1 : 1) + this.innerIndex
      if (idx >= 0 && idx < len) {
        this.innerIndex = idx
      }
    }
  }
}
</script>
