<template>
  <el-cascader
    ref="cascader"
    placeholder="请选择"
    v-model="nodeIds"
    v-bind="$attrs"
    :options="subjectList"
    :props="{ label: 'subjectValue', value: 'id' }"
    @change="handleChange"
  />
</template>

<script>
import {
  commonSubsectionList,
} from '@/api/question'

export default {
  name: 'FormItemSubject',
  props: {
    value: {
      type: Number,
      default: undefined
    }
  },
  data () {
    return {
      subjectList: [],
      nodeIds: [],
    }
  },
  watch: {
    value: {
      immediate: true,
      handler (val) {
        this.nodeIds = this.getNodeIds(val)
      }
    }
  },
  methods: {
    loadData () {
      return commonSubsectionList().then(res => {
        if (res.code === 0) {
          this.subjectList = res.data.map(m => ({
            id: 's' + m.sort,
            subjectValue: m.subsection,
            children: m.subjects
          }))
          this.nodeIds = this.getNodeIds(this.value)
          this.$emit('loaded', this.subjectList)
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    getNodeIds (id) {
      if (id && this.subjectList.length) {
        const idx = this.subjectList.findIndex(section =>
          section.children.findIndex(subject => subject.id === id) >= 0
        )
        if (idx >= 0) {
          return [this.subjectList[idx].id, id]
        }
      }
      return []
    },

    handleChange (val) {
      const data = val.length ? val[val.length - 1] : undefined
      this.$emit('input', data)

      const checkedNodes = this.$refs.cascader.getCheckedNodes()
      this.$emit('change', checkedNodes && checkedNodes.length ? checkedNodes[0] : null)
    }
  },
  mounted () {
    this.loadData()
  }
}
</script>
