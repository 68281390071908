<template>
  <div class="quill-editor">
    <slot name="toolbar"></slot>
    <div ref="editor"></div>
  </div>
</template>

<script>
import Quill from 'quill'

Quill.imports['ui/icons'].clean =
  '<svg class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3506" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="M887.39 566.25L760.01 438.87l154.15-154.15c11.31-11.31 11.31-29.65 0-40.96L780.32 109.9c-11.31-11.31-29.65-11.31-40.96 0L585.2 264.06 457.69 136.54c-11.31-11.31-29.65-11.31-40.96 0L305.95 247.32l470.67 470.67 110.77-110.77c11.31-11.32 11.31-29.66 0-40.97zM109.84 443.43c-11.31 11.31-11.31 29.65 0 40.96l61.96 61.96 85.68-85.68 43.7 43.7-85.68 85.68 87.53 87.53 129.32-129.32 43.7 43.7-129.32 129.33 87.28 87.28 85.68-85.68 43.7 43.7-85.68 85.68 61.83 61.83c11.31 11.31 29.65 11.31 40.96 0l158.06-158.06L267.9 285.37 109.84 443.43z" fill="#2c2c2c" p-id="3507"></path></svg>'

export default {
  name: 'QuillEditor',
  data () {
    return {
      content: '',
    }
  },
  props: {
    value: String,
    disabled: {
      type: Boolean,
      default: false
    },
  },
  beforeDestroy () {
    this.quill = null
  },
  methods: {
    // Init Quill instance
    initialize (options) {
      if (this.$el) {
        // Instance
        // const options = Object.assign({}, defaultOptions, opts)
        this.quill = new Quill(this.$refs.editor, options)

        // Set editor content
        if (this.value) {
          this.quill.pasteHTML(this.value)
        }

        this.quill.enable(!this.disabled)

        // Mark model as touched if editor lost focus
        this.quill.on('selection-change', range => {
          if (!range) {
            this.$emit('blur', this.quill)
          } else {
            this.$emit('focus', this.quill)
          }
        })

        // Update model if text changes
        this.quill.on('text-change', (delta, oldDelta, source) => {
          const quill = this.quill
          const text = quill.getText()
          const html = this.$refs.editor.children[0].innerHTML
          const content = html !== '<p><br></p>' ? html : ''
          this.content = content
          this.$emit('input', content)
          this.$emit('change', { content, text, quill })
        })

        // Emit ready event
        this.$emit('ready', this.quill)
      }
    }
  },
  watch: {
    // Watch content change
    value (newVal, oldVal) {
      if (this.quill) {
        if (newVal && newVal !== this.content) {
          this.content = newVal
          this.quill.pasteHTML(newVal)
        } else if (!newVal) {
          this.content = ''
          this.quill.setText('')
        }
      }
    },
    // Watch disabled change
    disabled (newVal) {
      if (this.quill) {
        this.quill.enable(!newVal)
      }
    }
  }
}
</script>
