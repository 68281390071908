import Quill from 'quill'
const Parchment = Quill.import('parchment')

const typeList = [
  'uline', // \uuline{下划线}\par
  'uuline', // \uuline{双下划线}\par
  'uwave', // \uwave{波浪线}\par
  'sout', // \sout{中间删除线}\par
  'dashuline', // \dashuline{虚线}\par
  'dotuline', // \dotuline{加点线}

  'emphasis', // (着重号)
  'key', // key_symbol (着重号下)
]

class ClassAttributor extends Parchment.Attributor.Class {
  add (node, value) {
    // console.log('ClassAttributor add', node, value)
    return super.add(node, value)
  }

  canAdd (node, value) {
    // console.log('ClassAttributor canAdd', node, value)
    const val = super.canAdd(node, value)
    return val
  }

  value (node) {
    // console.log('ClassAttributor value', node)
    const val = super.value(node) || undefined
    return val
  }
}

const MarkClass = new ClassAttributor('mark', 'ql-mark', {
  scope: Parchment.Scope.INLINE,
  whitelist: typeList
})

export { MarkClass }
