import { v4 } from 'uuid'
// TODO: 与文件上传功能合并，不需要Vue全局挂载

/**
 * 创建上传文件路径
 */
export default class Common {
  /**
   * 获取图片后缀
   * @param imageType
   * @returns {string}
   */
  getImageSuffix4ImageType (imageType) {
    switch (imageType) {
      case 'image/png':
        return 'png'
      case 'image/jpeg':
        return 'jpeg'
    }
  }

  /**
   * 获取UUID
   * @returns {*|string}
   */
  getUuid () {
    return v4()
  }

  /**
   * 获取日期(年-月-日)
   */
  getMyd () {
    const date = new Date()
    return date.getFullYear() + '-' + this.addZero(date.getMonth() + 1) + '-' + date.getDate()
  }

  addZero (num) {
    return num > 9 ? num : '0' + num
  }
}
