import Quill from 'quill'
// const Embed = Quill.import('blots/embed')
const Embed = Quill.import('parchment').Embed

class FieldBlot extends Embed {
  static blotName = 'field'
  static tagName = 'SPAN'
  static className = 'ql-field'

  static create (params) {
    const length = this.sanitize(params.length)
    const node = super.create()
    const char = '　'

    node.setAttribute('contenteditable', false)
    node.setAttribute('type', params.type)
    node.setAttribute('length', length)
    node.innerHTML = char.repeat(length)
    // const m = document.createElement('span')
    // // m.setAttribute('contenteditable', false)
    // m.innerText = params.type === 'line'
    //   ? char.repeat(length)
    //   : '(' + char.repeat(length) + ')'
    // node.appendChild(m)
    return node
  }

  static formats (domNode) {
    return {
      type: domNode.getAttribute('type'),
      length: domNode.getAttribute('length'),
    }
  }

  static sanitize (value) {
    return Number(value) || 2
  }

  static value (domNode) {
    return {
      type: domNode.getAttribute('type'),
      length: domNode.getAttribute('length'),
    }
  }
}

export { FieldBlot }
