// 退出登录
import request from '@/utils/request'

/**
 * OCR识别传坐标
 * @param data
 * @returns {AxiosPromise}
 */
export function imageToLatex (data) {
  return request({
    timeout: 60000,
    url: '/common/imageToLatex',
    method: 'POST',
    data
  })
}

/**
 * 图片转Latex文本
 * @param {String} imageUrl
 * @returns {AxiosPromise}
 */
export function fullImageToLatex (imageUrl) {
  return request({
    url: 'question/bank/image/to/latex',
    method: 'GET',
    params: {
      file: imageUrl
    }
  })
}

/**
 * 阿里OSS STS授权
 * @returns {AxiosPromise}
 */
export function aliSTS () {
  return request({
    method: 'GET',
    url: '/common/sts/info?key=oss'
  })
}
