<template>
  <el-upload
    class="ui-uploader"
    action=""
    :accept="acceptFormat"
    :file-list="fileList"
    :limit="limitNum"
    :show-file-list="showFileList"
    :on-exceed="handleExceed"
    :on-remove="handleRemove"
    :on-success="handleSuccess"
    :before-upload="handleBeforeUpload"
    :http-request="httpRequest"
    v-bind="$attrs"
  >
    <slot name="default" slot="default" v-bind:scope="{
      loading,
      fileList
    }">
      <el-button type="primary">上传文件</el-button>
    </slot>
    <slot name="tip" slot="tip"></slot>

    <slot name="preview" v-if="!showFileList"></slot>
  </el-upload>
</template>

<script>
import { fileUpload } from '@/api/question-bank'
import { SETTING } from '@/config/setting'
import { getUuid, getDate, getFileExtension } from '@/utils/common'

// <ui-uploader
//   show-file-list
//   type="image"
//   v-model="list"
//   :beforeUpload="beforeUpload"
//   :on-preview="handlePreview"
// >
//   <el-button type="primary">点击上传</el-button>
//   <div slot="tip" class="el-upload__tip">Tips：提示</div>
// </ui-uploader>

export default {
  name: 'Uploader',
  props: {
    value: {
      type: [Array, String],
      default: null
    },
    type: {
      type: String,
      default: 'file'
    },
    accept: {
      type: String,
      default: undefined
    },
    limit: {
      type: Number,
      default: 1
    },
    maxSize: {
      type: Number,
      default: 10 // 单位：M，0: 不限
    },
    showFileList: {
      type: Boolean,
      default: false
    },
    beforeUpload: {
      type: Function,
      required: false
    },
    onError: {
      type: Function,
      required: false
    },
    OSSClient: {
      type: String,
      default: '',
    },
    baseUrl: {
      type: String,
      default: '',
    }
  },
  computed: {
    acceptFormat () {
      if (this.accept) {
        return this.accept
      }
      if (this.type === 'image') {
        return 'image/*'
      }
      return undefined
    },
    limitNum () {
      // 不显示文件列表, 且仅限单文件上传，上传数量限制由 handleBeforeUpload 管理
      return !this.showFileList && this.limit === 1 ? 0 : this.limit
    }
  },
  watch: {
    value: {
      immediate: true,
      handler (val) {
        if (val) {
          const list = typeof val === 'string' ? [val] : val
          this.fileList = list.map(item => ({
            name: item.split('/').pop(),
            url: item
          }))
        }
      }
    },
  },
  data () {
    return {
      loading: false,
      fileList: [],
      // fileList: [
      // { name: 'food.jpeg', url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100' },
      // {
      //   name: 'food2.jpeg',
      //   raw: File,
      //   response: ApiResponse,
      //   size: 28769,
      //   status: 'success',
      //   uid: 1660710287862
      // }]
    }
  },
  methods: {
    // 文件上传成功时的钩子
    handleSuccess (response, file, fileList) {
      console.log('--- handleSuccess', response, file, fileList)
      if (!this.showFileList && this.limit === 1) {
        file.url = response.data
        this.fileList = [file]
      }
      this.emitData(this.fileList)
    },
    handleRemove (file, fileList) {
      // console.log('--- handleRemove: ', file, fileList)
      this.emitData(fileList)
    },
    handleExceed (files, fileList) {
      console.warn('---- handleExceed', fileList)
      // 本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件
      this.$message.warning(`当前限制选择 ${this.limit} 个文件`)
      return true
    },
    // handleBeforeRemove (file, fileList) {
    //   if (this.beforeRemove) {
    //     return this.beforeRemove(file, fileList)
    //   }
    //   return this.$confirm(`确定移除 ${file.name}？`)
    // },
    handleBeforeUpload (file) {
      console.warn('---- handleBeforeUpload', this.fileList)

      if (this.beforeUpload) {
        return this.beforeUpload(file)
      }

      if (this.accept) {
        if (!file.name) { return false }
        const idx = this.accept.split(',').findIndex(m => {
          const suffix = m.trim()
          // 文件名以 suffix 为后缀，且不为 `.abc`
          return file.name.endsWith(suffix) && (file.name !== suffix)
        })
        if (idx < 0) {
          this.$message.error('文件类型不允许')
          return false
        }
      }

      if (this.maxSize) {
        const maxSize = this.maxSize * 1024 * 1024
        if (maxSize < file.size) {
          this.$message.error(`文件大小不能超过 ${this.maxSize}MB`)
          return false
        }
      }
      return true
    },
    emitData (list) {
      this.fileList = list
      // TODO: 原始数据与后上传对象结构不同，需要分别处理
      const event = 'input'
      if (this.limit === 1) {
        const idx = list.findIndex(m => m.status === 'success')
        this.$emit(event, idx < 0 ? '' : list[idx].url)
      } else {
        const data = []
        list.forEach(m => {
          if (m.status === 'success') {
            data.push(m.url)
          }
        })
        this.$emit(event, data)
      }
      this.$emit('action', this)
    },

    httpRequest (config) {
      switch (this.OSSClient.toUpperCase()) {
        case 'ALIOSS':
          return this.uploadAliOSS(config)
        default:
          return this.uploadCommon(config)
      }
    },

    // 阿里云服务
    uploadAliOSS (config) {
      const file = config.file
      const filePath = this.baseUrl + getDate('') + '/' + getUuid() + getFileExtension(file.name)

      this.loading = true
      this.$oss.put(filePath, file).finally(() => {
        this.loading = false
      }).then(() => {
        // this.$message.success('上传成功')
        const url = SETTING.URL.IMAGE_URL + '/' + filePath
        config.onSuccess({ code: 0, data: url }, file, this.fileList)
      }).catch(err => {
        config.onError(err, file, this.fileList)
      })
    },

    // 服务器直传
    uploadCommon (config) {
      this.loading = true
      const data = Object.assign({
        [config.filename]: config.file
      }, config.data)
      fileUpload(data, config.headers).finally(() => {
        this.loading = false
      }).then(res => {
        if (res.code === 0) {
          // config.file.url = 'xxx'
          config.onSuccess(res, config.file, this.fileList)
        } else {
          throw new Error(res.msg)
        }
      }).catch(err => {
        config.onError(err, config.file, this.fileList)
      })
    }
  }
}
</script>

<style lang="scss">
.ui-uploader {
  position: relative;
  display: inline-block;

  .el-upload {
    display: block;
    text-align: inherit;
  }
}
</style>
