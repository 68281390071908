// 后台接口
export const URLS = {
  // 用户登录
  USER_LOGIN: '/api/user-login',
  // 用户
  USER_LOGOUT: '/api/user-logout',
  // 发送短信
  SEND_SMS: '/sms/behavior/verify',
  // 新增题库
  BANK_ADD: '/question/bank/add',
  // 教辅列表
  MATERIAL_LIST: '/teachingMaterial/get?grade=%s&subject=%s',
  // 刷新token
  REFRESH_TOKEN: '/api/refresh-token',
  // 题库列表
  QUESTION_BANK_LIST: '/question/bank/add/list',
  // 题库详情
  QUESTION_BANK_DETAIL: '/question/bank/info?id=%s',
  // 图片上传
  QUESTION_IMAGE_UPLOAD: '/question/bank/image',
  // 题库审核
  QUESTION_AUDIT: '/question/bank/audit',
  // 删除题库
  DELETE_QUESTION: '/question/bank/delete',
  // 编辑题库
  EDIT_QUESTION: '/question/bank/update',
  // 题库审核
  CHECK_REPEAT: '/question/bank/check/repeat',
  // 题型列表
  QUESTION_TYPE_LIST: '/teachingMaterial/question/type/list?grade=%s&subject=%s',
  // 根据教辅ID和页码获取图片url
  MATERIAL_IMAGE: '/teachingMaterial/bank/image?materialId=%d&pageNumber=%d',
  // 编辑题库
  QUESTION_BANK_CORRECT: '/question/bank/correct'
}
