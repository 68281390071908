/* eslint-disable */
import { Shape } from './Shape/Shape'
import { Polygon } from './Shape/Polygon'
import { Rect } from './Shape/Rect'

const shapeClasses = {
  rect: Rect,
  polygon: Polygon
}

const canvasModes = {
  DRAW: 'draw', // 画
  DRAG: 'drag', // 拖拽点
  DRAG_SCALE: 'dragScale', // 拖拽点缩放
  MOVE: 'move', // 移动shape
  DEFAULT: 'default' // 默认
}

export default class Canvas {
  constructor (ctx, actions = {}) {
    this.ctx = ctx
    this.shapeList = []
    this.typeList = []
    this.shapeListName = []
    this.startX = 0
    this.startY = 0
    this.drawingShape = 'point'
    this.drawingShapeObject = null
    this.movingShapeObject = null
    this.draggingShapeObject = null
    this.scalingShapeObject = null
    this.mode = canvasModes.DEFAULT

    this.actions = actions
  }

  deleteAll () {
    this.shapeList = []
    this.typeList = []
    this.shapeListName = []
  }

  setDrawingShape (props) {
    this.drawingShape = props.shape
  }

  pack (scale) {
    return this.shapeList.map((shape, index) => ({
      isActive: shape.isActive,
      $data: shape.$data,
      point: shape.pack(scale),
      shape: this.shapeListName[index]
    }))
  }

  /**
   * 删除选中的HighLight的shape
   * @param {points} props 鼠标位置
   * drawingShapeObject movingShapeObject draggingShapeObject 是否需要修改?
   */
  removeShape (props) {
    if (this.shapeList.length === 0 && !this.drawingShapeObject) return

    // 当有正在绘画的图象时，不可操作其他图像
    if (this.drawingShapeObject) {
      const knob = this.drawingShapeObject.isPointOnKnob(props)
      if (knob !== -1) {
        this.drawingShapeObject.points.splice(knob, 1)
        if (this.drawingShapeObject.points.length === 0) this.drawingShapeObject = null
      }
      return
    }

    // 判断删除元素索引
    let removeIndex = -1
    for (let i = 0; i < this.shapeList.length; i++) {
      const shape = this.shapeList[i]
      if (!shape.isActive) continue
      if (shape.isPointInPath(props) || shape.isPointOnKnob(props) !== -1) {
        removeIndex = i
      }
    }
    if (removeIndex === -1) return

    // 提前存储下来删除前的各个shape上的文字提示 如为有序标注 则在下面用来更新
    const preAnnotations = this.shapeList.map((e, i) => {
      return e.tipText
    })

    this.shapeList.splice(removeIndex, 1)
    this.shapeListName.splice(removeIndex, 1)
    this.typeList.splice(removeIndex, 1)
    // console.log(shape, type)
    this.shapeList.forEach((shape, i) => {
      // 如为有序 更新tipText 即 文字提示
      if (shape.isOrderly) shape.tipText = preAnnotations[i]
      // 取消高亮
      if (shape.isHighlighted === true) {
        shape.setHighlight(false)
      }
    })
    for (let i = 0; i < this.shapeList.length; i++) {
      const shape = this.shapeList[i]
      if (!shape.isActive) continue
      if (shape.isPointInPath(props) || shape.isPointOnKnob(props) !== -1) {
        shape.setHighlight(true)
        break
      }
    }

    this.clear()
    this.render()
  }

  leftMouseDown (props) {
    /* 当鼠标划出canvas区域并up，此时mode仍为DRAW，再次点击时需结束,并重置mode为DEFAULT */
    // console.log('leftMouseDown:', this.mode, this.drawingShapeObject)
    if (this.mode === canvasModes.DRAW && this.drawingShapeObject) {
      this.endDraw(props)
      this.mode = canvasModes.DEFAULT
      return
    }
    // console.log(this.shapeList)
    // 因 判断在点上是设置误差值的，所以在点上时（isPointOnKnob）不一定在path（isPointInPath）上
    // let shapeListAll = this.drawingShapeObject ? this.shapeList.concat([this.drawingShapeObject]) : this.shapeList.concat([])
    for (let i = 0; i < this.shapeList.length; i++) {
      let shape = this.shapeList[i]
      // if (!shape.isActive) continue
      // 激活选框
      if (!shape.isActive) {
        if (this.actions.activeShape && shape.isPointInPath(props)) {
          if (!this.actions.activeShape(shape, this)) return
        }
        continue
      }

      /* DRAG */
      const knob = shape.isPointOnKnob(props)
      if (knob !== -1) {
        if (shape instanceof Rect) {
          // Rect will be dragged into a polygon
          shape = shape.toPolygon()
          this.shapeListName.splice(i, 1, 'polygon')
          this.shapeList.splice(i, 1, shape)
        }
        shape.startDrag(knob)
        this.draggingShapeObject = shape
        if (shape.scaleSwitch) {
          this.mode = canvasModes.DRAG_SCALE
        } else {
          this.mode = canvasModes.DRAG
        }
        return
      }
      /* MOVE */
      if (shape.isPointInPath(props) && shape.moveAble) {
        this.movingShapeObject = shape
        this.mode = canvasModes.MOVE
        return
      }
    }
    if (!this.actions.beforeDraw || this.actions.beforeDraw(this, props)) {
      this.mode = canvasModes.DRAW
      this.startDraw(props)
    } else {
      this.mode = canvasModes.DEFAULT
    }
  }

  leftMouseUp (props) {
    switch (this.mode) {
      case canvasModes.DRAW:
        this.endDraw(props)
        break
      case canvasModes.MOVE:
      case canvasModes.DRAG:
      case canvasModes.DRAG_SCALE:
      case canvasModes.DEFAULT:
        break
      default:
        throw `Illegal mode ${this.mode} on mouseUp`
    }
    // this.draggingShapeObject = null
    this.mode = canvasModes.DEFAULT
  }

  leftMouseMove (props) {
    switch (this.mode) {
      case canvasModes.DRAW:
        this.updateDraw(props)
        break
      case canvasModes.MOVE:
        this.movingShapeObject.move(props)
        break
      case canvasModes.DRAG:
        this.draggingShapeObject.setHighlight(true)
        this.draggingShapeObject.drag(props)
        break
      case canvasModes.DRAG_SCALE:
        this.draggingShapeObject.setHighlight(true)
        this.draggingShapeObject.dragScale(props)
        break
      case canvasModes.DEFAULT:
        if (this.drawingShapeObject && this.drawingShapeObject.mouseTracking) this.drawingShapeObject.mouseTracking(props)
        this.setHighlight(props)
        break
    }
    this.clear()
    this.render()
  }

  addShape (shape, shapeName) {
    // console.log("shape是",type)
    if (!(shape instanceof Shape)) {
      throw `${shape} is not an instance of Shape`
    }
    this.shapeList.push(shape)
    this.shapeListName.push(shapeName)
    this.typeList.push(shape.assignType)
  }

  clear () {
    this.ctx.clearRect(0, 0, this.ctx.canvas.width, this.ctx.canvas.height)
  }

  render () {
    // console.log('render')
    if (this.mode === canvasModes.DRAG) {
      this.draggingShapeObject.draw(this.ctx)
    }
    if (this.drawingShapeObject) {
      this.drawingShapeObject.draw(this.ctx)
    }
    this.shapeList.forEach((shape, i) => {
      shape.draw(this.ctx)
    })

    // 当前draw的并未在shapeList中，故需要单独画出
    if (this.drawingShapeObject !== null) {
      this.ctx.strokeStyle = this.drawingShapeObject.strokeStyle
      this.ctx.lineJoin = this.drawingShapeObject.lineJoin
      this.ctx.lineWidth = this.drawingShapeObject.lineWidth
      this.ctx.stroke(this.drawingShapeObject.pathStroke)
      if (this.drawingShapeObject.pathKnobs && this.drawingShapeObject.knobShowAlways) {
        this.drawingShapeObject.pathKnobs.forEach(e => {
          this.ctx.stroke(e)
        })
      }
    }
  }

  startDraw (props) {
    this.setDrawingShape(props)
    this.startX = props.x
    this.startY = props.y
    if (!this.drawingShapeObject || this.drawingShapeObject.autoComplete) {
      this.drawingShapeObject = new shapeClasses[this.drawingShape]({
        ...props,
        ctx: this.ctx
      })
    } else {
      this.continueDraw(props)
    }
    this.mode = canvasModes.DRAW
  }

  endDraw (props) {
    if (!this.drawingShapeObject) return
    if (this.drawingShapeObject.autoComplete || this.drawingShapeObject.canCompleteDraw(props)) {
      if (this.drawingShapeObject.isVisible()) {
        this.addShape(this.drawingShapeObject, this.drawingShape)
      }
      this.drawingShapeObject = null
    } else {
      // wait continue
    }
    this.clear()
    this.render()
  }

  updateDraw (props) {
    if (this.drawingShapeObject !== null) {
      if (
        this.drawingShapeObject instanceof Rect
      ) {
        this.drawingShapeObject.updateDraw(props)
      }
      this.drawingShapeObject.buildPath()
    }
  }

  continueDraw (props) {
    if (this.drawingShapeObject.continueDraw) {
      this.drawingShapeObject.continueDraw(props)
    } else {
      this.updateDraw(props)
    }
  }

  cancelDraw () {
    this.drawingShapeObject = null
    this.clear()
    this.render()
  }

  setHighlight (props) {
    this.shapeList.forEach(shape => {
      if (shape.isHighlighted === true) {
        shape.setHighlight(false)
      }
    })
    for (let i = 0; i < this.shapeList.length; i++) {
      const shape = this.shapeList[i]
      if (!shape.isActive) continue
      if (shape.isPointInPath(props) || shape.isPointOnKnob(props) !== -1) {
        shape.setHighlight(true)
        break
      }
    }
  }

  startMove (props) {
    this.startX = props.x
    this.startY = props.y
  }

  setShapeActiveByType (type) {
    this.shapeList.forEach(shape => {
      shape.setActive(shape.assignType == type)
    })
    this.clear()
    this.render()
  }

  isDefault () {
    return this.mode === canvasModes.DEFAULT
  }
}
