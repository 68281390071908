import { SETTING } from '@/config/setting'
import { questionType } from '@/utils/enums'
import { message } from '@/element/message'

const engine = require('store/src/store-engine')
const storages = [require('store/storages/localStorage'), require('store/storages/cookieStorage')]
const plugins = [require('store/plugins/defaults'), require('store/plugins/expire')]

export const NStore = engine.createStore(storages, plugins)

export function checkApiData (res) {
  if (res.code !== SETTING.NUMBER.ZERO) {
    message({ type: 'error', message: res.msg })
    return false
  }
  return true
}

/**
 * 获取到题型Label
 * @param id
 * @returns {null}
 */
export function getQuestionType (id) {
  let label = null
  questionType.forEach(item => {
    if (item.id === id) {
      label = item.value
    }
  })
  return label
}

/**
 * 发送消息模块
 * @param {*} msg
 * @param {*} type
 */
export function sendMsg (msg, type) {
  let box = document.getElementById('msgBox')
  if (!box) {
    box = document.createElement('div')
    box.setAttribute('id', 'msgBox')
    box.setAttribute('style', 'position: fixed;top: 0;left: 50%;transform: translateX(-50%);display: flex;flex-direction: column;z-index: 10000')
    document.body.appendChild(box)
  }
  const el = document.createElement('i')
  const sy = {
    display: 'block',
    width: '360px',
    padding: '10px 15px',
    borderRadius: '5px',
    fontStyle: 'normal',
    fontSize: '16px',
    marginTop: '10px',
    transition: 'all .3s linear'
  }
  for (const key in sy) {
    el.style[key] = sy[key]
  }

  let text = ''
  let cl = ''
  let bgCl = ''
  if (type === SETTING.STRING.MSG_BOX_STATUS.ERROR) {
    text = '错误：' + msg
    cl = '#f56c6c'
    bgCl = '#fde2e2'
  } else if (type === SETTING.STRING.MSG_BOX_STATUS.WARN) {
    text = '提示：' + msg
    cl = '#E6A23C'
    bgCl = '#FDF6EC'
  } else if (type === SETTING.STRING.MSG_BOX_STATUS.SUCCESS) {
    text = '成功：' + msg
    cl = '#67C23A'
    bgCl = '#F0F9EB'
  } else {
    text = type + msg
    cl = '#606266'
    bgCl = '#f4f4f5'
  }
  el.style.color = cl
  el.style.backgroundColor = bgCl
  el.innerText = text
  box.appendChild(el)

  setTimeout(() => {
    el.style.marginTop = '0'
    el.style.opacity = '0'
    setTimeout(() => {
      el.remove()
    }, 300)
  }, 2000)
}

// 分数框体
export function showFractionDialog (bl) {
  const v = bl ? 'block' : 'none'
  document.getElementById('mask').style.display = v
  document.getElementById('dialog-fraction').style.display = v
}

/**
 * 光标处插入文本
 * @param {*} obj 可输入元素
 * @param {*} str 插入字符串
 * @returns
 */
export function insertText (obj, str) {
  if (!str) return
  if (document.selection) {
    const sel = document.selection.createRange()
    sel.text = str
  } else if (typeof obj.selectionStart === 'number' && typeof obj.selectionEnd === 'number') {
    const startPos = obj.selectionStart
    const endPos = obj.selectionEnd
    let cursorPos = startPos
    const tmpStr = obj.value
    obj.value = tmpStr.substring(SETTING.NUMBER.ZERO, startPos) + str + tmpStr.substring(endPos, tmpStr.length)
    cursorPos += str.length
    obj.selectionStart = obj.selectionEnd = cursorPos
  } else {
    obj.value += str
  }
}

export const KEY_SYMBOL_CLASS_NAME = 'key_symbol'

export function definedKeySymbolStyle () {
  let style = ''
  style += '<style>'
  style += '.' + KEY_SYMBOL_CLASS_NAME + ' {'
  style += ' position: relative;'
  style += '}'
  style += '.' + KEY_SYMBOL_CLASS_NAME + '::after {'
  style += 'content: \'.\';'
  style += ' position: absolute;'
  style += ' left: 50%;'
  style += ' transform: translate(-50%, 0);'
  style += ' bottom: -25px;'
  style += ' line-height: 56px;'
  style += ' color: #000;'
  style += ' font-weight: bold;'
  style += '  }'
  style += ' </style>'
  return style
}
