import Quill from 'quill'
const Parchment = Quill.import('parchment')

class ParagraphAttributor extends Parchment.Attributor.Class {
  add (node, value) {
    return super.add(node, value)
  }

  canAdd (node, value) {
    return super.canAdd(node, value)
  }

  value (node) {
    return super.value(node)
  }
}

const ParagraphClass = new ParagraphAttributor('paragraph', 'ql-paragraph', {
  scope: Parchment.Scope.BLOCK,
  whitelist: ['indent']
})

export { ParagraphClass }
