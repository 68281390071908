import Edition from './Edition'
import QuestionType from './QuestionType'
import Subject from './Subject'
import TeachingMaterial from './TeachingMaterial'

import CheckRepeat from './CheckRepeat'
import Setting from './Setting'

export default {
  Edition,
  QuestionType,
  Subject,
  TeachingMaterial,

  CheckRepeat,
  Setting,
}
