/* eslint-disable */
import Quill from 'quill'
// import IconAlignLeft from 'file-loader!quill/assets/icons/align-left.svg'
// import IconAlignCenter from 'file-loader!quill/assets/icons/align-center.svg'
// import IconAlignRight from 'file-loader!quill/assets/icons/align-right.svg'
import { BaseModule } from './BaseModule'

const Parchment = Quill.imports.parchment
// const FloatStyle = new Parchment.Attributor.Style('float', 'float')
// const MarginStyle = new Parchment.Attributor.Style('margin', 'margin')
// const DisplayStyle = new Parchment.Attributor.Style('display', 'display')
const AlignClass = new Parchment.Attributor.Class('align', 'ql-align');

export class Toolbar extends BaseModule {
  onCreate = () => {
    // Setup Toolbar
    this.toolbar = document.createElement('div')
    Object.assign(this.toolbar.style, this.options.toolbarStyles)
    this.overlay.appendChild(this.toolbar)

    // Setup Buttons
    this._defineAlignments()
    this._addToolbarButtons()
  };

  // The toolbar and its children will be destroyed when the overlay is removed
  onDestroy = () => { }

  // Nothing to update on drag because we are are positioned relative to the overlay
  onUpdate = () => { }

  _defineAlignments = () => {
    this.alignments = [
      {
        // icon: IconAlignLeft,
        icon: Quill.import('ui/icons').align[''],
        apply: () => {
          // DisplayStyle.add(this.img, 'inline')
          // FloatStyle.add(this.img, 'left')
          // MarginStyle.add(this.img, '0 1em 1em 0')
          AlignClass.remove(this.img.parentNode)
        },
        // isApplied: () => FloatStyle.value(this.img) === 'left',
        isApplied: () => AlignClass.value(this.img.parentNode) === '',
      },
      {
        icon: Quill.import('ui/icons').align['center'],
        apply: () => {
          // DisplayStyle.add(this.img, 'block')
          // FloatStyle.remove(this.img)
          // MarginStyle.add(this.img, 'auto')
          AlignClass.add(this.img.parentNode, 'center')
        },
        // isApplied: () => MarginStyle.value(this.img) === 'auto',
        isApplied: () => AlignClass.value(this.img.parentNode) === 'center',
      },
      {
        icon: Quill.import('ui/icons').align['right'],
        apply: () => {
          // DisplayStyle.add(this.img, 'inline')
          // FloatStyle.add(this.img, 'right')
          // MarginStyle.add(this.img, '0 0 1em 1em')
          AlignClass.add(this.img.parentNode, 'right')
        },
        // isApplied: () => FloatStyle.value(this.img) === 'right',
        isApplied: () => AlignClass.value(this.img.parentNode) === 'right',
      },
    ]
  }

  _addToolbarButtons = () => {
    const buttons = []
    this.alignments.forEach((alignment, idx) => {
      const button = document.createElement('span')
      buttons.push(button)
      button.innerHTML = alignment.icon
      button.addEventListener('click', () => {
        // deselect all buttons
        buttons.forEach(button => { button.style.filter = '' })
        if (alignment.isApplied()) {
          // // If applied, unapply
          // FloatStyle.remove(this.img)
          // MarginStyle.remove(this.img)
          // DisplayStyle.remove(this.img)
          AlignClass.remove(this.img.parentNode)
        } else {
          // otherwise, select button and apply
          this._selectButton(button)
          alignment.apply()
        }
        // image may change position; redraw drag handles
        this.requestUpdate()
      })
      Object.assign(button.style, this.options.toolbarButtonStyles)
      if (idx > 0) {
        button.style.borderLeftWidth = '0'
      }
      Object.assign(button.children[0].style, this.options.toolbarButtonSvgStyles)
      if (alignment.isApplied()) {
        // select button if previously applied
        this._selectButton(button)
      }
      this.toolbar.appendChild(button)
    })
  }

  _selectButton = (button) => {
    button.style.filter = 'invert(20%)'
  }
}
