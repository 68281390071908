<template>
  <div class="page" v-loading="loading">
    <el-row>
      <el-col :span="8" v-if="operationType === 'teachingMaterial'">
        <div class="panel">
          <div class="panel-header">图片区</div>
          <div class="panel-main">
            <div class="panel-cnt">
              <ui-section-picker
                class="picker"
                ref="sectionPicker"
                disabled
                locked
                :value="pickerData"
                @change="sectionChange"
              />
              <div class="picker-footer">
                <el-button size="small" type="primary" @click="handleOCR">OCR识别</el-button>

                <ui-pagination
                  @current-change="handlePageChange"
                  :current-page.sync="pageCurrent"
                  :pager-count="5"
                  :page-list="pageList"
                  small
                  layout="->, prev, pager, next"
                />
              </div>
            </div>
          </div>
        </div>
      </el-col>

      <el-col :span="operationType === 'teachingMaterial' ? 8 : 16">
        <div class="grid-content bg-purple">
          <div class="panel">
            <div class="panel-header">操作区</div>
            <div class="panel-main">
              <div class="panel-cnt">
                <el-form size="small" class="questionInfo">
                  <el-form-item label="学段" class="form-item">
                    <cmp-subject
                      v-model="formData.subsectionId"
                      @change="handleSetSubject"
                      disabled
                    />
                  </el-form-item>

<!--                  <el-form-item label="版本" class="form-item">-->
<!--                    <cmp-edition-->
<!--                      v-model="formData.standardTeachingMaterialId"-->
<!--                      :subsectionId="formData.subsectionId"-->
<!--                      disabled-->
<!--                      placeholder="请选择"-->
<!--                    />-->
<!--                  </el-form-item>-->

<!--                  <el-form-item label="教辅" class="form-item">-->
<!--                    <cmp-teaching-material-->
<!--                      v-model="formData.teachingMaterialId"-->
<!--                      :editionId="formData.standardTeachingMaterialId"-->
<!--                      disabled-->
<!--                      placeholder="请选择教辅"-->
<!--                    />-->
<!--                  </el-form-item>-->

                  <el-form-item label="题型" class="form-item">
                    <cmp-question-type v-model="formData.questionType" :subsectionId="formData.subsectionId" clearable placeholder="请选择" />
                  </el-form-item>

<!--                  <el-form-item label="页码" class="form-item">-->
<!--                    <el-input-number v-model="formData.pageNumber" :controls="false" placeholder="页码" :min="1" :step="1" />-->
<!--                  </el-form-item>-->

                  <el-form-item label="题号" class="form-item">
                    <el-input-number :controls="false" v-model="formData.titleNumber" clearable placeholder="请输入"/>
                  </el-form-item>
                </el-form>

                <div class="editor-top">
                  <ui-uploader type="image" @action="getLatex" class="ext" v-if="operationType !== 'teachingMaterial'">
                    <template v-slot:default="{ scope }" >
                      <el-button size="mini" type="primary" :loading="scope.loading">OCR识别</el-button>
                    </template>
                  </ui-uploader>

                  <el-radio-group v-model="editorType" size="mini">
                    <el-radio-button label="stem">题干</el-radio-button>
                    <el-radio-button label="answer">答案</el-radio-button>
                    <el-radio-button label="analysis">解析</el-radio-button>
                  </el-radio-group>
                </div>

                <!-- <ui-editor class="editor-main" v-model="formData.stem"/>-->
                <ui-editor class="editor-main" v-model="editorData"/>

                <div class="editor-foot">
                  <div class="upload-item">
                    <ui-uploader
                      v-model="formData.audioResource.url"
                      accept="audio/*"
                    >
                      <img src="~@/assets/icon/microphone.svg" class="icon-btn" />

                      <div slot="preview" class="preview">
                        <div v-if="formData.audioResource.url" class="audio-handle">
                          <audio
                            :src="formData.audioResource.url"
                            controls
                          ></audio>
                          <el-button type="text" icon="el-icon-delete" class="btn-remove" @click.stop="clearFile(formData.audioResource)"></el-button>
                        </div>
                        <span v-else class="placeholder">上传音频</span>
                      </div>
                    </ui-uploader>
                  </div>

                  <div class="upload-item">
                    <ui-uploader
                      v-model="formData.videoResource.url"
                      accept="video/*"
                    >
                      <img src="~@/assets/icon/video.svg" class="icon-btn" />

                      <div slot="preview" class="preview">
                        <div
                          v-if="formData.videoResource.url"
                          class="video-handle"
                        >
                          <video :src="formData.videoResource.url"></video>
                          <div class="mask" @click.stop="videoPlay"><i class="el-icon-video-play"></i></div>
                          <el-button type="text" icon="el-icon-delete" class="btn-remove" @click.stop="clearFile(formData.videoResource)"></el-button>
                        </div>
                        <span v-else class="placeholder">上传视频</span>
                      </div>
                    </ui-uploader>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-col>

      <el-col :span="8">
        <div class="grid-content bg-purple">
          <div class="panel">
            <div class="panel-header">预览区</div>
            <div class="panel-main">
              <div class="panel-cnt">
                <ui-mathpad :value="formData.stem" />
              </div>
              <div class="panel-cnt">
                <ui-mathpad :value="formData.answer" />
              </div>
              <div class="panel-cnt">
                <ui-mathpad :value="formData.analysis" />
              </div>

              <el-button type="primary" size="small" class="btn-submit" @click="handleSubmit">完成</el-button>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>

    <el-button type="warning" class="btn-setting" @click="showSetting">
      <i class="el-icon-set-up" style="font-size: 18px;"></i>
    </el-button>

    <cmp-setting
      ref="Setting"
      @submit="handleSetting"
    />

    <cmp-check-repeat
      ref="CheckRepeat"
      @submit="changeRepeat"
    />

    <ui-preview type="video" ref="PreviewVideo" />
  </div>
</template>

<script>
import { Subject } from '@/config/enums/Subject'

import { imageToLatex, fullImageToLatex } from '@/api/common'
import {
  questionEdit,
  questionFind,
  checkRepeat,
} from '@/api/recordQuestion'
import Form from './modules/form'
import { getQuestionBankImage } from '@/api/branchImport'

export default {
  name: 'QuestionEdit',
  components: {
    // 'cmp-edition': Form.Edition,
    'cmp-question-type': Form.QuestionType,
    'cmp-subject': Form.Subject,
    // 'cmp-teaching-material': Form.TeachingMaterial,

    'cmp-check-repeat': Form.CheckRepeat,
    'cmp-setting': Form.Setting,
  },
  data () {
    return {
      loading: false,
      operationType: 'teachingMaterial', // 题目所属教材：custom - 自定义，不关联教材 | teachingMaterial - 教辅题目，有教辅相关图片识别定位信息
      questionData: null,

      pageCurrent: 1, // 页面ID：即pageList数组索引+1
      pageList: [],
      // pageList: [
      //   { no: 1, url: 'page/1.png' },
      //   { no: 2, url: 'page/2.png' },
      // ],
      sectionList: [],
      pickerData: null, // ui-section-picker 渲染数据

      formData: {
        subsectionId: undefined, // 学段
        subject: '', // 学科

        standardTeachingMaterialId: undefined, // 教材
        teachingMaterialId: undefined, // 教辅
        questionType: undefined, // 题型 - 文本
        pageNumber: undefined, // 页码
        titleNumber: '', // 题号

        stem: '', // 题干
        answer: '', // 答案
        analysis: '', // 解析

        audioResource: { url: '' }, // 音频 - https://cdn2.ear0.com:3321/preview?soundid=37260&type=mp3
        videoResource: { url: '' }, // 视频 - https://test-hnjy-user-base.oss-cn-beijing.aliyuncs.com/record/bank/20220822/e312a0fa-db7c-4201-879a-82610e568bf3.jpg

        chapter: [],
        knowledge: [],
        keyAccomplishment: [],
        // chapter: [{ chapterId: 1001 }], // 章节
        // knowledge: [{ knowledgeId: 1001 }], // 知识点
        // keyAccomplishment: [{ keyAccomplishmentId: 1 }],
        // coordinate: [ // 图片坐标 - 需特殊处理
        //   { page: 1, coordinate: { x_min: 19.0456, x_max: 57.0145, y_min: 151.1564, y_max: 52.0112 } }
        // ]
      },
      editorType: 'stem',
    }
  },
  computed: {
    // TODO: editorData set方法改为 editor组件@input响应事件更直观
    editorData: {
      get () {
        return this.editorType ? this.formData[this.editorType] : ''
      },
      set (val) {
        if (!this.editorType) {
          return this.$message.error('请确认编辑题干')
        }
        this.formData[this.editorType] = val
      }
    }
  },
  mounted () {
    console.log('QuestionEdit')

    const query = this.$route.query
    if (query.id && query.teachingMaterialId) {
      this.loadData({
        questionId: query.id,
        teachingMaterialId: query.teachingMaterialId
      }).then(() => {
        const pageInfo = this.questionData.coordinateInfo || []
        this.loadMaterialPages(query.teachingMaterialId, pageInfo.length ? pageInfo[0].page : 1)
      })
    } else {
      this.$alert('缺少题目和教辅信息').then(() => {
        this.$router.back()
      })
    }
  },
  methods: {
    /**
     * 题目数据转换
     * @param {Array} questions 题目列表
     * @param {Boolean|String} active 是否可变更 | 当前激活分组
     * @param {Object} props 数据属性
     * @returns {Array}
     */
    parseSections (questions, active = true, props = { pageKey: 'page', groupKey: 'id' }) {
      const sections = []
      questions.forEach(question => {
        const pageList = question.coordinate
        const group = question[props.groupKey]
        // const isActive = typeof active !== 'boolean' ? active === group : active
        pageList.forEach(p => {
          p.coordinate.forEach(m => {
            // 坐标深拷贝，避免选区修改且不提交产生副作用
            sections.push({
              isActive: false, // 可变更标记
              group, // 题目Id - 分组标记
              pageId: p[props.pageKey], // 当前页
              // label: question.titleNumber, // 选区显示label
              // $data: question // 原始数据
              coordinate: m
            })
          })
        })
      })
      return sections
    },

    parseQuestion (questionData) {
      // 初始化表单
      function format (question, key) {
        const val = question[key]
        return val
      }
      const mode = [
        'questionId',
        'subsectionId',
        'subject',
        'teachingMaterialId',
        'standardTeachingMaterialId',
        'questionType',
        'pageNumber',
        'titleNumber',
        'stem',
        'answer',
        'analysis',
      ]
      mode.forEach(key => {
        this.formData[key] = format(questionData, key)
      })

      this.formData.coordinateInfo = questionData.coordinate
      if (questionData.chapterIds) {
        this.formData.chapter = questionData.chapterIds.map(m => ({ chapterId: m }))
      }
      if (questionData.knowledgeIds) {
        this.formData.knowledge = questionData.knowledgeIds.map(m => ({ knowledgeId: m }))
      }
      if (questionData.keyAccomplishmentIds) {
        this.formData.keyAccomplishment = questionData.keyAccomplishmentIds.map(m => ({ keyAccomplishmentId: m }))
      }

      // TODO: OCR选区 - coordinateInfo:Array
      // if (questionData.images.length) {
      //   // OCR识别 - 加载照片
      //   this.operationType = 'teachingMaterial'
      //   this.pageCurrent = 1 // questionData.images[0].page
      //   this.pageList = questionData.images.map(item => ({
      //     no: item.page,
      //     url: item.image
      //   }))
      //
      //   this.sectionList = this.parseSections([questionData])
      //   this.handlePageChange(this.pageList[this.pageCurrent - 1])
      // }
    },

    // 图片转Latex
    getLatex (uploader) {
      const file = uploader.fileList.find(m => m.status === 'success')
      if (!file) return
      uploader.loading = true
      return fullImageToLatex(file.response.data).then(res => {
        if (res.code === 0) {
          if (res.data && this.editorType) {
            this.formData[this.editorType] += res.data
          }
        } else {
          this.$message.error(res.msg)
        }
      }).finally(() => {
        uploader.loading = false
      })
    },

    // 加载题目详情
    loadData (params) {
      this.loading = true
      return questionFind(params).then(res => {
        if (res.code === 0) {
          this.questionData = res.data

          this.parseQuestion(res.data)
        } else {
          this.$message.error(res.msg)
        }
      }).finally(() => {
        this.loading = false
      })
    },

    // 加载教辅信息 - 页面图片列表
    loadMaterialPages (materialId, curPageNum) {
      return getQuestionBankImage(materialId).then(res => {
        if (res.code === 0) {
          let pageIndex = 0
          this.pageList = res.data
            .sort((a, b) => a.page - b.page)
            .map((m, index) => {
              const p = { no: m.page, url: m.questionImage }
              if (curPageNum && p.no === curPageNum) {
                pageIndex = index
              }
              return p
            })
          if (this.pageList.length) {
            this.operationType = 'teachingMaterial'

            this.$nextTick(() => {
              this.pageCurrent = pageIndex + 1
              this.handlePageChange(this.pageList[pageIndex])
            })
          }
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    // 图片选区变更
    sectionChange (list) {
      console.log('sectionChange 图片选区变更', list)
      const pageNo = this.pageList[this.pageCurrent - 1].no
      this.sectionList = this.sectionList
        .filter(sec => sec.pageId !== pageNo) // 删除当前页旧坐标
        .concat(list.map(m => ({
          pageId: pageNo,
          coordinate: this.handlePosition(m.point),
          isActive: true
        }))) // 添加新坐标
    },

    // 坐标数据转换
    handlePosition (point) {
      return {
        x_min: +point[0].x.toFixed(2),
        y_min: +point[0].y.toFixed(2),
        x_max: +point[2].x.toFixed(2),
        y_max: +point[2].y.toFixed(2)
      }
    },

    // 图片翻页
    handlePageChange (page) {
      console.log('当前页: ', page)
      // this.$refs.sectionPicker.pageRender(page.url, this.sectionList, page.no)
      const coordinate = []
      this.sectionList.forEach(m => {
        if (m.pageId === page.no) {
          coordinate.push(m.coordinate)
        }
      })
      this.pickerData = {
        page: page.no,
        bg: page.url,
        coordinate
      }
    },

    // OCR识别 -  提交选区坐标，返回OCR识别信息
    handleOCR () {
      const result = this.sectionList.map(sec => {
        const page = this.pageList.find(p => p.no === Number(sec.pageId))
        return Object.assign({
          imageUrl: page.url
        }, sec.coordinate)
      })
      if (result.length === 0) {
        return this.$message.error('请添加题目选区')
      }

      this.loading = true
      imageToLatex(result).then(res => {
        if (res.code === 0) {
          this.$message.success('ORC识别完成')
          // this.tgContent - 题干内容
          this.tgContent = res.data
        } else {
          this.$message.error(res.msg)
        }
      }).finally(() => {
        this.loading = false
      })
    },

    // 选择学段
    handleSetSubject (node) {
      console.log(node)
      let subjectInfo
      const subjectName = node ? node.label : ''
      if (subjectName) {
        subjectInfo = Subject.find(m => m.value === subjectName)
      }
      this.formData.subject = subjectInfo ? subjectInfo.id : ''
    },

    handleSubmit () {
      // 1. 数据校验
      const data = Object.assign({}, this.formData)
      if (!data.subsectionId) {
        return this.$message.error('请选择学段')
      }
      if (!data.teachingMaterialId) {
        return this.$message.error('请选择版本')
      }
      // if (!data.standardTeachingMaterialId) {
      //   return this.$message.error('请选择教辅')
      // }
      if (!data.questionType) {
        return this.$message.error('请选择题型')
      }
      // if (!data.pageNumber) {
      //   return this.$message.error('请选择页码')
      // }
      if (!data.titleNumber) {
        return this.$message.error('请选择题号')
      }
      if (!data.stem) {
        return this.$message.error('请输入题干')
      }

      if (data.stem !== this.questionData.stem) {
        // 2. 查重 - 题干变更
        this.loading = true
        checkRepeat({
          stem: data.stem,
          subsectionId: data.subsectionId,
        }).finally(() => {
          this.loading = false
        }).then(res => {
          if (res.code === 0) {
            res.data.length
              ? this.$refs.CheckRepeat.show(data, res.data)
              : this.saveQuestion(data)
          } else {
            this.$message.error(res.msg)
          }
        })
      } else {
        this.saveQuestion(data)
      }
    },

    // 查重弹窗 确认
    changeRepeat (e) {
      const params = Object.assign({}, e.origin)
      if (e.bankId) {
        params.questionId = e.bankId
      }
      this.saveQuestion(params)
    },

    // 打标签
    handleSetting (data) {
      this.formData.chapter = data.chapterIds.map(m => ({ chapterId: m }))
      this.formData.knowledge = data.knowledgeIds.map(m => ({ knowledgeId: m }))
      this.formData.keyAccomplishment = data.keyAccomplishment.map(m => ({ keyAccomplishmentId: m }))
    },

    saveQuestion (questionData) {
      this.loading = true
      questionEdit(questionData).then(res => {
        if (res.code === 0) {
          this.$confirm('是否返回题目列表', '提交成功').then(() => {
            this.$router.push({
              name: 'QuestionList'
            })
          }).catch(() => {})
        } else {
          this.$message.error(res.msg)
        }
      }).finally(() => {
        this.loading = false
      })
    },

    // 显示题目属性设置弹窗
    showSetting () {
      this.$refs.Setting.show(this.formData)
    },

    // 删除已上传文件
    clearFile (target) {
      this.$confirm('确认删除文件').then(() => {
        target.url = ''
      }).catch(() => {})
    },

    // 播放视频
    videoPlay () {
      this.$refs.PreviewVideo.show(this.formData.videoResource.url)
    }
  }
}
</script>

<style lang="scss" scoped>
$space: 10px;

.page {
  padding: $space $space/2 0;
}

// 功能分区布局
.panel {
  margin: 0 $space/2;

  &-header {
    font-size: 16px;
    line-height: 40px;
    padding: 0 $space;
    background: rgba(24, 194, 194, 0.2);
  }

  &-main {
    height: calc(100vh - 120px);
    display: flex;
    flex-direction: column;
  }

  &-cnt {
    flex: 1;
    padding: $space;
    background: #FFF;

    & + & {
      margin: $space 0;
    }
  }
}

// 图片选区
.page .picker {
  height: calc(100% - 40px);

  &-footer {
    position: relative;
    margin-top: $space;

    > button {
      float: left;
    }
  }
}

// 题目操作区 - 表单
.questionInfo /deep/ {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 -10px;

  .el-form-item {
    box-sizing: border-box;
    width: 50%;
    max-width: 250px;
    padding: 0 10px;
    display: flex;

    &__label {
      word-break: keep-all;
    }

    &__content {
      flex: 1;
    }
  }

  .el-select {
    width: 100%;
  }
}

// 提交按钮
.btn-submit {
  width: 100%;
}

// 编辑器
.editor {
  &-top {
    .ext {
      float: right;
    }
  }

  &-foot {
    font-size: 12px;
    height: 95px;
    padding: 0 5px;
    border: 1px solid #f6f6f6;
    color: #999;

    .upload-item {
      margin: 5px 0;
    }

    .icon-btn {
      float: left;
      margin: 0 3px;
      width: 16px;
      height: 32px;
      vertical-align: middle;
      opacity: 0.6;
      color: #ccc;

      &:hover {
        opacity: 1;
      }
    }

    .preview {
      margin-left: 24px;
      min-height: 32px;

      audio {
        height: 32px;
        vertical-align: bottom;
      }
    }

    .placeholder {
      line-height: 30px;
    }

    .audio-handle {
      background: #F1F3F4;
      white-space: nowrap;
      padding-right: 16px;

      .btn-remove {
        padding: 8px 0;
        color: #000;
      }
    }

    .video-handle {
      position: relative;
      border: 1px solid #f6f6f6;
      height: 47px;
      width: 62px;

      video {
        display: block;
        height: 100%;
        width: 100%;
      }

      .mask {
        position: absolute;
        box-sizing: border-box;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;

        padding-top: 12px;
        font-size: 18px;
        text-align: center;
        background: rgba(0, 0, 0, .5);
        color: #fff;
      }

      .btn-remove {
        position: absolute;
        top: 0;
        right: 0;
        padding: 2px;
        color: #fff;
        text-shadow: 0 1px 1px #000;
      }
    }
  }

  &-main {
    margin: 10px 0;
    height: calc(100vh - 440px);
  }
}

// 设置按钮
.btn-setting {
  position: fixed;
  right: 0;
  top: 100px;
}
</style>
