<template>
  <div class="page" v-loading="loading">
    <el-row>
      <el-col :span="8" v-if="operationType === 'teachingMaterial'">
        <div class="panel">
          <div class="panel-header">图片区</div>
          <div class="panel-main">
            <div class="panel-cnt">
              <ui-section-picker
                class="picker"
                ref="sectionPicker"
                locked
                :value="pickerData"
                @change="sectionChange"
              />
              <div class="picker-footer">
                <el-button size="small" type="primary" @click="handleOCR">OCR识别</el-button>

                <ui-pagination
                  @current-change="handlePageChange"
                  :current-page.sync="pageCurrent"
                  :pager-count="5"
                  :page-list="pageList"
                  small
                  layout="->, prev, pager, next, jumper"
                />
              </div>
            </div>
          </div>
        </div>
      </el-col>

      <el-col :span="operationType === 'teachingMaterial' ? 8 : 16">
        <div class="grid-content bg-purple">
          <div class="panel">
            <div class="panel-header">操作区</div>
            <div class="panel-main">
              <div class="panel-cnt">
                <el-form size="small" class="questionInfo">
                  <el-form-item label="学段" class="form-item">
                    <cmp-subject v-model="formData.subsectionId" disabled @change="handleSetSubject" />
                  </el-form-item>

                  <el-form-item label="版本" class="form-item">
                    <cmp-edition
                      v-model="formData.standardTeachingMaterialId"
                      :subsectionId="formData.subsectionId"
                      clearable
                      disabled
                      placeholder="请选择"
                    />
                  </el-form-item>

                  <el-form-item label="教辅" class="form-item">
                    <cmp-teaching-material
                      v-model="formData.teachingMaterialId"
                      :editionId="formData.standardTeachingMaterialId"
                      clearable
                      disabled
                      placeholder="请选择教辅"
                    />
                  </el-form-item>

                  <el-form-item label="题型" class="form-item">
                    <cmp-question-type
                      v-model="formData.questionType"
                      :subsectionId="formData.subsectionId"
                      clearable
                      placeholder="请选择"
                    />
                  </el-form-item>

                  <!--<el-form-item label="页码" class="form-item">-->
                  <!--  <el-input-number v-model="formData.pageNumber" :controls="false" placeholder="页码" :min="1" :step="1" />-->
                  <!--</el-form-item>-->

                  <el-form-item label="题号" class="form-item">
                    <el-input-number :controls="false" v-model="formData.titleNumber" clearable placeholder="请输入"/>
                  </el-form-item>
                </el-form>

                <div class="editor-top">
                  <ui-uploader type="image" @action="getLatex" class="ext" v-if="operationType !== 'teachingMaterial'">
                    <template v-slot:default="{ scope }" >
                      <el-button size="mini" type="primary" :loading="scope.loading">OCR识别</el-button>
                    </template>
                  </ui-uploader>

                  <el-radio-group v-model="editorType" size="mini">
                    <el-radio-button label="stem">题干</el-radio-button>
                    <el-radio-button label="answer">答案</el-radio-button>
                    <el-radio-button label="analysis">解析</el-radio-button>
                  </el-radio-group>
                </div>

                <ui-editor class="editor-main" v-model="editorData"/>

                <div class="editor-foot">
                  <div class="upload-item">
                    <ui-uploader
                      v-model="formData.audioResource.url"
                      accept="audio/*"
                    >
                      <img src="~@/assets/icon/microphone.svg" class="icon-btn" />

                      <div slot="preview" class="preview">
                        <div v-if="formData.audioResource.url" class="audio-handle">
                          <audio
                            :src="formData.audioResource.url"
                            controls
                          ></audio>
                          <el-button type="text" icon="el-icon-delete" class="btn-remove" @click.stop="clearFile(formData.audioResource)"></el-button>
                        </div>
                        <span v-else class="placeholder">上传音频</span>
                      </div>
                    </ui-uploader>
                  </div>

                  <div class="upload-item">
                    <ui-uploader
                      v-model="formData.videoResource.url"
                      accept="video/*"
                    >
                      <img src="~@/assets/icon/video.svg" class="icon-btn" />

                      <div slot="preview" class="preview">
                        <div
                          v-if="formData.videoResource.url"
                          class="video-handle"
                        >
                          <video :src="formData.videoResource.url"></video>
                          <div class="mask" @click.stop="videoPlay"><i class="el-icon-video-play"></i></div>
                          <el-button type="text" icon="el-icon-delete" class="btn-remove" @click.stop="clearFile(formData.videoResource)"></el-button>
                        </div>
                        <span v-else class="placeholder">上传视频</span>
                      </div>
                    </ui-uploader>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-col>

      <el-col :span="8">
        <div class="grid-content bg-purple">
          <div class="panel">
            <div class="panel-header">预览区</div>
            <div class="panel-main">
              <div class="panel-cnt">
                <ui-mathpad :value="formData.stem" />
              </div>
              <div class="panel-cnt">
                <ui-mathpad :value="formData.answer" />
              </div>
              <div class="panel-cnt">
                <ui-mathpad :value="formData.analysis" />
              </div>

              <el-button type="primary" size="small" class="btn-submit" @click="handleSubmit">完成</el-button>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>

    <el-button type="warning" class="btn-setting" @click="showSetting">
      <i class="el-icon-set-up" style="font-size: 18px;"></i>
    </el-button>

    <cmp-setting
      ref="Setting"
      @submit="handleSetting"
    />

    <cmp-check-repeat
      ref="CheckRepeat"
      @submit="changeRepeat"
    />

    <ui-preview type="video" ref="PreviewVideo" />
  </div>
</template>

<script>
import { Subject } from '@/config/enums/Subject'

import { imageToLatex, fullImageToLatex } from '@/api/common'
import {
  questionAdd,
  checkRepeat,
} from '@/api/recordQuestion'
import Form from './modules/form'
import { getQuestionBankImage, getSubjectAndGrade } from '@/api/branchImport'

export default {
  name: 'QuestionEdit',
  components: {
    'cmp-edition': Form.Edition,
    'cmp-question-type': Form.QuestionType,
    'cmp-subject': Form.Subject,
    'cmp-teaching-material': Form.TeachingMaterial,

    'cmp-check-repeat': Form.CheckRepeat,
    'cmp-setting': Form.Setting,
  },
  data () {
    return {
      loading: false,
      operationType: 'teachingMaterial', // 题目所属教材：custom - 自定义，不关联教材 | teachingMaterial - 教辅题目，有教辅相关图片识别定位信息
      // questionData: null,

      pageCurrent: 1,
      pageList: [],
      // pageList: [{ no: 5, url: 'page/1.png' }, { no: 4, url: 'page/2.png' }],

      sectionList: [],
      pickerData: null, // ui-section-picker 渲染数据

      formData: {
        subsectionId: undefined, // 学段
        subject: '', // 学科

        standardTeachingMaterialId: undefined, // 教材
        teachingMaterialId: undefined, // 教辅
        questionType: undefined, // 题型 - 文本
        pageNumber: undefined, // 页码
        titleNumber: '', // 题号

        stem: '', // 题干
        answer: '', // 答案
        analysis: '', // 解析

        audioResource: { url: '' }, // 音频
        videoResource: { url: '' }, // 视频

        chapter: [],
        knowledge: [],
        keyAccomplishment: [],
        coordinate: [],
        // chapter: [{ chapterId: 1001 }], // 章节
        // knowledge: [{ knowledgeId: 1001 }], // 知识点
        // keyAccomplishment: [{ keyAccomplishmentId: 1 }],
        // coordinate: [ // 图片坐标
        //   { page: 1, coordinate: { x_min: 19.0456, x_max: 57.0145, y_min: 151.1564, y_max: 52.0112 } }
        // ]
      },
      editorType: 'stem',

      questionList: [],
    }
  },
  computed: {
    editorData: {
      get () {
        return this.editorType ? this.formData[this.editorType] : ''
      },
      set (val) {
        if (!this.editorType) {
          return this.$message.error('请确认编辑题干')
        }
        this.formData[this.editorType] = val
      }
    }
  },
  mounted () {
    const query = this.$route.query
    if (!(query.subjectId && query.editionId && query.materialId)) {
      this.$alert('录题前需选择 学段、教材、教辅 信息')
        .finally(() => { this.$router.back() })
      return
    }

    this.formData.subsectionId = Number(query.subjectId)
    this.formData.standardTeachingMaterialId = Number(query.editionId)

    // 测试： const materialId = 388
    const materialId = Number(query.materialId)
    this.formData.teachingMaterialId = materialId

    this.loading = true
    Promise.all([
      // this.loadMaterialInfo(materialId),
      this.loadSubjectAndGrade(materialId),
      this.loadMaterialPages(materialId),
    ]).finally(() => {
      this.loading = false
    })
  },
  methods: {
    init () {
      /**
       * 题目数据转换
       * @param {Array} questions 题目列表
       * @param {Boolean|String} active 是否可变更 | 当前激活分组
       * @param {Object} props 数据属性
       * @returns {Array}
       */
      function parseSections (questions, active = true, props = { pageKey: 'page', groupKey: 'id' }) {
        const sections = []
        questions.forEach(question => {
          const pageList = question.coordinate
          const group = question[props.groupKey]
          const isActive = typeof active !== 'boolean' ? active === group : active
          pageList.forEach(p => {
            p.coordinate.forEach(m => {
              // 坐标深拷贝，避免选区修改且不提交产生副作用
              sections.push(Object.assign({
                isActive, // 可变更标记
                group, // 题目Id - 分组标记
                page: p[props.pageKey], // 当前页
                label: question.titleNumber, // 选区显示label
                $data: question // 原始数据
              }, m))
            })
          })
        })
        return sections
      }

      return Promise.resolve([
        {
          id: '0x00001',
          titleNumber: '第1题',
          stem: '测试题干：\\(\\frac{1}{2}\\)',
          coordinate: [
            {
              page: 1,
              coordinate: [
                { x_min: 133, y_min: 225.47, x_max: 232.76, y_max: 261.02 },
                { x_min: 133.25, y_min: 325.47, x_max: 232.76, y_max: 561.02 }
              ]
            },
            {
              page: 2,
              coordinate: [
                { x_min: 30, y_min: 125, x_max: 832, y_max: 261 },
                { x_min: 30, y_min: 300, x_max: 832, y_max: 500 }
              ]
            }
          ]
        },
        {
          id: '0x00002',
          titleNumber: '第2题',
          stem: '测试2：\\(\\frac{1}{2}\\)',
          coordinate: [
            {
              page: 2,
              coordinate: [{ x_min: 133, y_min: 525, x_max: 800, y_max: 660 }]
            }
          ]
        }
      ]).then(list => {
        const sections = parseSections(list, '0x00002')
        // const sections = [
        //   { isActive: true, group: 'xxxxxx', label: '第1题', x_max: 892.76, x_min: 333.25, y_max: 831.02, y_min: 225.47, $data: {} },
        //   { isActive: true, group: 'xxxxxx', label: '第2题', x_max: 892.76, x_min: 333.25, y_max: 831.02, y_min: 225.47, $data: {} },
        // ]
        const pageNo = 2
        const image = 'https://image-question-bank.hnzypj.com/teaching-material/1/2.png'
        // const picker = this.$refs.sectionPicker.init()
        window.dt = this.$refs.sectionPicker.drawTool
        console.log('----- init', sections)
        this.$refs.sectionPicker.pageRender(image, sections, pageNo)

        // 锁定选区
        // this.$refs.sectionPicker.drawTool.locked = true
      })
    },

    // 图片转Latex
    getLatex (uploader) {
      const file = uploader.fileList.find(m => m.status === 'success')
      if (!file) return
      uploader.loading = true
      return fullImageToLatex(file.response.data).then(res => {
        if (res.code === 0) {
          if (res.data && this.editorType) {
            this.formData[this.editorType] += res.data
          }
        } else {
          this.$message.error(res.msg)
        }
      }).finally(() => {
        uploader.loading = false
      })
    },

    loadSubjectAndGrade (standardTeachingMaterialId) {
      return getSubjectAndGrade(standardTeachingMaterialId).then(res => {
        if (res.code === 0) {
          if (res.data) {
            this.subject = res.data.subject
            this.grade = res.data.grade
          } else {
            this.$message.error('未找到对应教辅')
          }
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    // 加载教辅信息 - 页面图片列表
    loadMaterialPages (materialId) {
      return getQuestionBankImage(materialId).then(res => {
        if (res.code === 0) {
          this.pageList = res.data.sort((a, b) => a.page - b.page).map(m => ({
            no: m.page,
            url: m.questionImage
          }))
          if (this.pageList.length) {
            this.operationType = 'teachingMaterial'
            this.$nextTick(() => {
              this.handlePageChange(this.pageList[0])
            })
          }
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    // 图片选区变更
    sectionChange (list) {
      // 先择题目
      if (this.type === 'edit') {
        const shapeList = this.$refs.sectionPicker.drawTool.regionCanvas.shapeList
        const idx = shapeList.findIndex(item => item.isActive)
        if (idx >= 0) {
          const question = shapeList[idx].$data
          if (this.questionList[this.curIndex].id !== question.id) {
            this.setCurrentQuestion(question.id)
          }
        }
        return
      }

      // 变更选区
      const pageNo = this.pageList[this.pageCurrent - 1].no
      this.sectionList = this.sectionList
        .filter(sec => sec.pageId !== pageNo) // 删除当前页旧坐标
        .concat(list.map(m => ({
          pageId: pageNo,
          coordinate: this.handlePosition(m.point)
        }))) // 添加新坐标
    },

    // 坐标数据转换
    handlePosition (point) {
      return {
        x_min: +point[0].x.toFixed(2),
        y_min: +point[0].y.toFixed(2),
        x_max: +point[2].x.toFixed(2),
        y_max: +point[2].y.toFixed(2)
      }
    },

    // 图片翻页
    handlePageChange (page) {
      const coordinate = this.sectionList.filter(m => m.pageId === page.no)
      this.pickerData = {
        page: page.no,
        bg: page.url,
        coordinate
      }
    },

    // OCR识别 -  提交选区坐标，返回OCR识别信息
    handleOCR () {
      const result = this.sectionList.map(sec => {
        const page = this.pageList.find(p => p.no === Number(sec.pageId))
        return Object.assign({
          imageUrl: page.url
        }, sec.coordinate)
      })
      if (result.length === 0) {
        return this.$message.error('请添加题目选区')
      }

      this.loading = true
      imageToLatex(result).then(res => {
        if (res.code === 0) {
          this.$message.success('ORC识别完成')
          // this.tgContent - 题干内容
          this.tgContent = res.data
        } else {
          this.$message.error(res.msg)
        }
      }).finally(() => {
        this.loading = false
      })
    },

    // 选择学段
    handleSetSubject (node) {
      console.log(node)
      let subjectInfo
      const subjectName = node ? node.label : ''
      if (subjectName) {
        subjectInfo = Subject.find(m => m.value === subjectName)
      }
      this.formData.subject = subjectInfo ? subjectInfo.id : ''
    },

    handleSubmit () {
      const data = Object.assign({}, this.formData, { coordinate: this.sectionList })
      if (!data.subsectionId) {
        return this.$message.error('请选择学段')
      }
      if (!data.teachingMaterialId) {
        return this.$message.error('请选择版本')
      }
      if (!data.standardTeachingMaterialId) {
        return this.$message.error('请选择教辅')
      }
      if (!data.questionType) {
        return this.$message.error('请选择题型')
      }
      // if (!data.pageNumber) {
      //   return this.$message.error('请选择页码')
      // }
      if (!data.titleNumber) {
        return this.$message.error('请选择题号')
      }
      if (!data.stem) {
        return this.$message.error('请输入题干')
      }

      // 查重
      this.loading = true
      checkRepeat({
        stem: data.stem,
        subsectionId: data.subsectionId,
      }).finally(() => {
        this.loading = false
      }).then(res => {
        if (res.code === 0) {
          res.data.length
            ? this.$refs.CheckRepeat.show(data, res.data)
            : this.saveQuestion(data)
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    // 查重弹窗 确认
    changeRepeat (e) {
      const params = Object.assign({}, e.origin)
      if (e.bankId) {
        params.questionId = e.bankId
      }
      this.saveQuestion(params)
    },

    // 打标签
    handleSetting (data) {
      this.formData.chapter = data.chapterIds.map(m => ({ chapterId: m }))
      this.formData.knowledge = data.knowledgeIds.map(m => ({ knowledgeId: m }))
      this.formData.keyAccomplishment = data.keyAccomplishment.map(m => ({ keyAccomplishmentId: m }))
    },

    saveQuestion (questionData) {
      this.loading = true
      questionData.subject = this.subject
      questionAdd(questionData).then(res => {
        if (res.code === 0) {
          this.$confirm('是否继续添加题目', '提交成功', {
            cancelButtonText: '返回列表',
            confirmButtonText: '继续添加'
          }).then(() => {
            // 重置题目主体信息 - 附加属性不变，章节、知识点等没有直观显示的参数需清空
            Object.assign(this.formData, {
              stem: '',
              answer: '',
              analysis: '',
              audioResource: { url: '' },
              videoResource: { url: '' },
              chapter: [],
              knowledge: [],
              keyAccomplishment: [],
              coordinate: []
            })
          }).catch(() => {
            this.$router.push({
              name: 'QuestionList'
            })
          })
        } else {
          this.$message.error(res.msg)
        }
      }).finally(() => {
        this.loading = false
      })
    },

    // 显示题目属性设置弹窗
    showSetting () {
      this.$refs.Setting.show(this.formData)
    },

    // 删除已上传文件
    clearFile (target) {
      this.$confirm('确认删除文件').then(() => {
        target.url = ''
      }).catch(() => {})
    },

    // 播放视频
    videoPlay () {
      this.$refs.PreviewVideo.show(this.formData.videoResource.url)
    }
  }
}
</script>

<style lang="scss" scoped>
$space: 10px;

.page {
  padding: $space $space/2 0;
}

.panel {
  margin: 0 $space/2;

  &-header {
    font-size: 16px;
    line-height: 40px;
    padding: 0 $space;
    background: rgba(24, 194, 194, 0.2);
  }

  &-main {
    height: calc(100vh - 120px);
    display: flex;
    flex-direction: column;
  }

  &-cnt {
    flex: 1;
    padding: $space;
    background: #FFF;

    & + & {
      margin: $space 0;
    }
  }
}

// 表格样式
.form-footer {
  display: flex;

  .el-button {
    flex: 1;
    margin: 0 $space;
  }
}

// 图片选区
.page {
  .picker {
    height: calc(100% - 40px);

    &-footer {
      position: relative;
      margin-top: $space;

      > button {
        float: left;
      }
    }
  }
}

// 题目操作区 - 表单
.questionInfo /deep/ {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 -10px;

  .el-form-item {
    box-sizing: border-box;
    width: 50%;
    max-width: 250px;
    padding: 0 10px;
    display: flex;

    &__label {
      word-break: keep-all;
    }

    &__content {
      flex: 1;
    }
  }

  .el-select {
    width: 100%;
  }
}

.btn-submit {
  width: 100%;
}

// 编辑器
.editor {
  &-top {
    .ext {
      float: right;
    }
  }

  &-foot {
    font-size: 12px;
    height: 95px;
    padding: 0 5px;
    border: 1px solid #f6f6f6;
    color: #999;

    .upload-item {
      margin: 5px 0;
    }

    .icon-btn {
      float: left;
      margin: 0 3px;
      width: 16px;
      height: 32px;
      vertical-align: middle;
      opacity: 0.6;
      color: #ccc;

      &:hover {
        opacity: 1;
      }
    }

    .preview {
      margin-left: 24px;
      min-height: 32px;

      audio {
        height: 32px;
        vertical-align: bottom;
      }
    }

    .placeholder {
      line-height: 30px;
    }

    .audio-handle {
      background: #F1F3F4;
      white-space: nowrap;
      padding-right: 16px;

      .btn-remove {
        padding: 8px 0;
        color: #000;
      }
    }

    .video-handle {
      position: relative;
      border: 1px solid #f6f6f6;
      height: 47px;
      width: 62px;

      video {
        display: block;
        height: 100%;
        width: 100%;
      }

      .mask {
        position: absolute;
        box-sizing: border-box;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;

        padding-top: 12px;
        font-size: 18px;
        text-align: center;
        background: rgba(0, 0, 0, .5);
        color: #fff;
      }

      .btn-remove {
        position: absolute;
        top: 0;
        right: 0;
        padding: 2px;
        color: #fff;
        text-shadow: 0 1px 1px #000;
      }
    }
  }

  &-main {
    margin: 10px 0;
    height: calc(100vh - 440px);
  }
}

// 设置
.btn-setting {
  position: fixed;
  right: 0;
  top: 100px;
}

.setting-form {
  position: relative;
  height: calc(100vh - 125px);
  min-height: 450px;
  margin: 20px 50px;

  .form-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
}
</style>
