import { NStore } from '@/utils/common.fun'
const state = {
  searchData: NStore.get('page_batchImport'),

  subject: NStore.get('subjectInfo'),
  editionGrade: NStore.get('editionGradeInfo'),
  lesson: NStore.get('lessonInfo'),
  scrollHeight: NStore.get('scrollHeight') || 0
}

const mutations = {
  SET_INFO: (state, data) => {
    NStore.set('page_batchImport', data)
    state.searchData = data
  },
  SET_SUBJECT: (state, data) => {
    NStore.set('subjectInfo', data)
    state.subject = data
  },
  SET_EDITION_GRADE: (state, data) => {
    NStore.set('editionGradeInfo', data)
    state.editionGrade = data
  },
  SET_LESSON: (state, data) => {
    NStore.set('lessonInfo', data)
    state.lesson = data
  },
  SET_SCROLL_HEIGHT: (state, data) => {
    NStore.set('scrollHeight', data)
    state.scrollHeight = data
  },
  REMOVE_SCROLL_HEIGHT: () => {
    NStore.remove('scrollHeight')
  }
}

const actions = {

}

export default {
  state,
  mutations,
  actions
}
