import Quill from 'quill'
import { FieldBlot } from './FieldBlot'
import { getEventComposedPath } from '../../utils'
const Module = Quill.import('core/module')

class FieldModule extends Module {
  static register () {
    Quill.register('formats/field', FieldBlot)
  }

  constructor (quill, options) {
    super(quill, options)
    this.quill.root.addEventListener('dblclick', evt => {
      evt.preventDefault()
      const path = getEventComposedPath(evt)
      const node = path.find(m => {
        return m.tagName &&
          m.tagName.toUpperCase() === FieldBlot.tagName &&
          m.classList.contains(FieldBlot.className)
      })
      if (node) {
        console.log('--- ', quill.getSelection(true))
        this.handleSetting(node)
      }
    }, true)
  }

  handleSetting (node) {
    const type = node.getAttribute('type')
    const length = node.getAttribute('length') || 2
    const n = Number(window.prompt('修改出入框宽度（字数)', length))
    if (n && n > 0) {
      const range = this.quill.getSelection(true)
      this.quill.deleteText(range.index, 1)
      this.quill.insertEmbed(range.index, FieldBlot.blotName, { type, length: n > 30 ? 30 : n }, 'user')
      this.quill.setSelection(range.index, 1)
    }
  }
}

export { FieldModule }
