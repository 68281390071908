// 题库状态
export const QuestionStatus = [
  {
    id: 'PENDING_REVIEW',
    value: '待审核'
  }, {
    id: 'REVIEW_PASSED',
    value: '审核通过'
  }, {
    id: 'REVIEW_FAILURE',
    value: '审核失败'
  }, {
    id: 'ALL',
    value: '全部'
  }
]
