/* eslint-disable */
import { Shape } from './Shape'

export class Polygon extends Shape {
  constructor (props) {
    super(props)
    this.points = props.points || [] // points are stored in anticlockwise order
    this.draggingKnobIndex = -1
    this.knobMap = { // 对角线点对应索引
      '0': 2,
      '2': 0,
      '1': 3,
      '3': 1
    }
  }

  static restore(props) {
    let points = props.data.map(ele => ({
      x: ele.x,
      y: ele.y
    }))

    let tmp = {
      ...props,
      points: points
    }
    return new Polygon(tmp)
  }

  drawAnnotation(ctx) {
    // let p1 = this.getKnobs()[0],
    //     p4 = this.getKnobs()[3];
    // let textWidth = ctx.measureText(this.tipText).width
    // let dx = (Math.abs(p1.x - p4.x) - textWidth) / 2
    // ctx.fillStyle = this.tipTextStyle
    // ctx.font = this.font
    // let textX = Math.min(p1.x, p4.x) + dx
    // let centerY = Math.min(p1.y, p4.y) + Math.abs((p1.y - p4.y) / 2)

    // TODO: correct tipText label position
    // ctx.fillText(this.tipText, textX, textY)


    const measure = ctx.measureText(this.tipText)
    const p1 = this.getKnobs()[0];
    const p4 = this.getKnobs()[3];

    const textX = Math.min(p1.x, p4.x) + 3
    const textY = Math.min(p1.y, p4.y) + 12

    ctx.fillStyle = this.tipTextStyle
    ctx.font = this.font
    ctx.fillText(this.tipText, textX, textY)
  }

  move(props) {
    this.points.forEach(p => {
      p.x += props.dx
      p.y += props.dy
    })
    this.buildPath()
  }

  scale(canvasScale) {
    this.points.forEach(point => {
      point.x *= canvasScale
      point.y *= canvasScale
    })
    this.buildPath()
  }

  startDrag(knob) {
    this.draggingKnobIndex = knob
  }

  drag(point) {
    // console.log(this.draggingKnobIndex)
    // let list = [1, 0, 3, 2]
    // let lenX = point.x - this.points[this.draggingKnobIndex].x;
    // let lenY = point.y - this.points[this.draggingKnobIndex].y;
    this.points[this.draggingKnobIndex].x = point.x
    this.points[this.draggingKnobIndex].y = point.y

    // this.points[list[this.draggingKnobIndex]].x += lenX
    // this.points[list[this.draggingKnobIndex]].y += lenY
    this.buildPath()
  }

  dragScale(point) {
    let centerPoint = this.points[this.knobMap[this.draggingKnobIndex]] // 取对角线的点
    let centerX = centerPoint.x
    let centerY = centerPoint.y
    let width = Math.abs(point.x - centerX)
    let height = Math.abs(point.y - centerY)
    let point0_X = Math.min(centerX, point.x)
    let point0_Y = Math.min(centerY, point.y)
    let minWidth = 1
    if (this.draggingKnobIndex === 0) {
      // if(point.x+10>=centerX || point.y+10>=centerY) return
      if (point.x + minWidth >= centerX) {
        width = minWidth;
        point0_X = this.points[this.knobMap[0]].x - minWidth
      }
      if (point.y + minWidth >= centerY) {
        height = minWidth;
        point0_Y = this.points[this.knobMap[0]].y - minWidth
      }
    } else if (this.draggingKnobIndex === 1) {
      // if(point.x+minWidth>=centerX || point.y-minWidth<=centerY) return
      if (point.x + minWidth >= centerX) {
        width = minWidth;
        point0_X = this.points[this.knobMap[1]].x - minWidth
      }
      if (point.y - minWidth <= centerY) {
        height = minWidth;
        point0_Y = this.points[this.knobMap[1]].y
      }
    } else if (this.draggingKnobIndex === 2) {
      // if(point.x-minWidth<=centerX || point.y-minWidth<=centerY) return
      if (point.x - minWidth <= centerX) {
        width = minWidth;
        point0_X = this.points[this.knobMap[2]].x
      }
      if (point.y - minWidth <= centerY) {
        height = minWidth;
        point0_Y = this.points[this.knobMap[2]].y
      }
    } else if (this.draggingKnobIndex === 3) {
      // if(point.x-minWidth<=centerX || point.y+minWidth>=centerY) return
      if (point.x - minWidth <= centerX) {
        width = minWidth;
        point0_X = this.points[this.knobMap[3]].x
      }
      if (point.y + minWidth >= centerY) {
        height = minWidth;
        point0_Y = this.points[this.knobMap[3]].y - minWidth
      }
    } else {
    }
    this.points = [
      {x: point0_X, y: point0_Y},
      {x: point0_X, y: point0_Y + height},
      {x: point0_X + width, y: point0_Y + height},
      {x: point0_X + width, y: point0_Y}
    ]
    this.buildPath()
  }

  getKnobs() {
    return this.points
  }

  buildPath() {
    this.pathStroke = new Path2D()
    this.pathFill = new Path2D()
    this.pathKnobs = []
    this.pathStroke.moveTo(this.points[0].x, this.points[0].y)
    this.pathFill.moveTo(this.points[0].x, this.points[0].y)
    for (let i = 1; i < this.points.length; i++) {
      this.pathStroke.lineTo(this.points[i].x, this.points[i].y)
      this.pathFill.lineTo(this.points[i].x, this.points[i].y)
    }
    this.pathStroke.closePath()
    this.pathFill.closePath()
    this.buildKnobs()
  }

  pack(scale) {
    scale = scale || 1
    return this.points.map(point => ({
      x: point.x / scale,
      y: point.y / scale
    }))
  }
}
