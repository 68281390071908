<template>
  <div ref="viewport" class="SectionPicker scrollbarStyle">
    <div ref="container" class="SectionPicker-container">
      <canvas ref="image"></canvas>
      <canvas ref="region"></canvas>
    </div>
  </div>
</template>

<script>
import DrawTool from './DrawTool'

export default {
  name: 'UiSectionPicker',
  props: {
    value: {
      type: Object,
      default: null
    },
    disabled: { // 是否禁止新增选区
      type: Boolean,
      default: false
    },
    locked: { // 是否锁定已提交题目的标记
      type: Boolean,
      default: false
    },
    action: { // 回调函数
      type: Function,
      default: null
    },
  },
  data () {
    return {
      sections: null,
      drawTool: null
    }
  },
  watch: {
    value: {
      immediate: true,
      handler (val) {
        if (this.drawTool && val) {
          this.pageRender(val.bg, val.coordinate)
        }
      }
    }
  },
  mounted () {
    this.loading = true
    this.init()
    if (this.value) {
      this.pageRender(this.value.bg, this.value.coordinate)
    }
  },
  methods: {
    /**
     * 初始化标记工具
     */
    init () {
      const drawTool = new DrawTool(
        this.$refs.viewport,
        this.$refs.region,
        this.$refs.image,
        this.$refs.container,
        this.callback,
        {
          /**
           * 点击激活已禁用选框
           * @return {Boolean} 是否允许穿透 (针对重叠标注框，默认不穿透)
           */
          activeShape: (shape, stage) => {
            if (this.locked) return true // 不响应点击操作
            stage.shapeList.forEach(item => {
              item.setActive(shape.$data.id === item.$data.id)
            })
            drawTool.render()
          },

          /**
           * Draw前置操作
           * @return {Boolean} 是否执行新增选框操作
           */
          beforeDraw: (stage, props) => {
            return !this.disabled
          }
        }
      )
      this.drawTool = drawTool
      return this
    },

    /**
     * callback
     */
    callback () {
      const datalist = this.drawTool.pick()
      if (this.action) {
        this.action(datalist, this)
      }
      this.$emit('change', datalist)
    },

    /**
     * 绘制选区
     * @param {String} pageImg 背景图片
     * @param {Array} sections 选区信息
     * @param {Number} pageId 页码
     * @returns {void|*}
     */
    pageRender (pageImg, sections = [], pageId = 0) {
      const pickerTool = this.drawTool
      if (!pickerTool) {
        return console.error('<SectionPicker> 未初始化成功')
      }
      this.sections = sections
      const list = pageId ? sections.filter(item => item.page === pageId) : sections
      const drawSharp = () => pickerTool.drawSharp(list)
      pickerTool.clearCanvas()
      return pickerTool.loadImage(pageImg)
        .then(drawSharp)
        .catch((err) => {
          console.error('加载失败,请刷新页面重新操作', err)
          throw err
        })
    },

    // 锁定所有选框
    disableAllShape () {
      if (this.drawTool) {
        this.drawTool.regionCanvas.shapeList.forEach(shape => shape.setActive(false))
        this.drawTool.render()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.SectionPicker {
  position: relative;
  background: #F6F6F6;
  border: 1px solid #EEE;
  box-sizing: border-box;
  margin: 0;
  z-index: 1;
  height: 100%;
  overflow: auto;
}
.SectionPicker-container {
  position: absolute;
  margin: 0;
  padding: 0;
  box-shadow: 0 0 3px #CCC;
}
.SectionPicker-container > canvas {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
</style>
