// 退出登录
import request from '@/utils/request'
import { URLS } from '@/config/api'
import { SETTING } from '@/config/setting'
import StringFormat from 'format'

/**
 * 获取到题库列表
 * @param data
 * @returns {AxiosPromise}
 */
export function getQuestionBankList (data) {
  return request({
    url: URLS.QUESTION_BANK_LIST,
    method: SETTING.HTTP.METHOD.POST,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: data
  })
}

/**
 * 获取到详情
 * @param id
 * @returns {AxiosPromise}
 */
export function getQuestionBankDetails (id) {
  return request({
    url: StringFormat(URLS.QUESTION_BANK_DETAIL, id),
    method: SETTING.HTTP.METHOD.GET,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: null
  })
}

/**
 * 图片上传 - 保存OSS文件文件地址
 * @param data
 * @returns {AxiosPromise}
 */
export function imageUpload (data) {
  return request({
    url: URLS.QUESTION_IMAGE_UPLOAD,
    method: SETTING.HTTP.METHOD.POST,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: data
  })
}

/**
 * 文件上传
 * @param data
 * @returns {AxiosPromise}
 */
export function fileUpload (data, config) {
  const formData = new FormData()
  Object.keys(data).forEach(key => {
    formData.append(key, data[key])
  })
  return request({
    withCredentials: config.withCredentials,
    headers: config.headers,
    onUploadProgress: config.onProgress,
    url: 'question/bank/image/upload',
    method: 'POST',
    data: formData
  })
}

/**
 * 新增题库
 * @param data
 * @returns {AxiosPromise}
 */
export function addQuestionBank (data) {
  return request({
    url: URLS.BANK_ADD,
    method: SETTING.HTTP.METHOD.POST,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: data
  })
}

/**
 * 题库审核
 * @param data
 * @returns {AxiosPromise}
 */
export function questionBankAudit (data) {
  return request({
    url: URLS.QUESTION_AUDIT,
    method: SETTING.HTTP.METHOD.POST,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: data
  })
}

/**
 * 删除
 * @param data
 * @returns {AxiosPromise}
 */
export function deleteQuestionBank (data) {
  return request({
    url: URLS.DELETE_QUESTION,
    method: SETTING.HTTP.METHOD.POST,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: data
  })
}

/**
 * 编辑
 * @param data
 * @returns {AxiosPromise}
 */
export function editQuestionBank (data) {
  return request({
    url: URLS.EDIT_QUESTION,
    method: SETTING.HTTP.METHOD.POST,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: data
  })
}

/**
 * 题目查重
 * @param data
 * @returns {AxiosPromise}
 */
export function chekRepeat (data) {
  return request({
    url: URLS.CHECK_REPEAT,
    method: SETTING.HTTP.METHOD.POST,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: data
  })
}

/**
 * 题型列表
 * @param data
 * @returns {AxiosPromise}
 */
export function questionTypeList (grade, subject) {
  return request({
    url: StringFormat(URLS.QUESTION_TYPE_LIST, grade, subject),
    method: SETTING.HTTP.METHOD.GET,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: null
  })
}

/**
 * 题库纠错
 * @param data
 * @returns {AxiosPromise}
 */
export function questionBankCorrect (data) {
  return request({
    url: URLS.QUESTION_BANK_CORRECT,
    method: SETTING.HTTP.METHOD.POST,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: data
  })
}

/**
 * 章节信息列表
 * standardMaterialId  标准教材id
 */
export function getChapterList (standardMaterialId) {
  return request({
    url: '/teaching/material/chapter/list/chapters?standardMaterialId=' + standardMaterialId,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    method: SETTING.HTTP.METHOD.GET,
    data: null
  })
}

/**
 * 课时列表
 * @returns {AxiosPromise}
 */
export function teachingMaterialLessonList (data) {
  return request({
    url: '/teachingMaterialLesson/list',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    method: SETTING.HTTP.METHOD.POST,
    data: data
  })
}

/**
 * 根据标准教材id获取题型
 * @returns {AxiosPromise}
 */
export function getStandardTeachingMaterialId (standardTeachingMaterialId) {
  return request({
    url: '/common/getQuestionType?standardTeachingMaterialId=' + standardTeachingMaterialId,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    method: SETTING.HTTP.METHOD.GET,
    data: null
  })
}

/**
 * 批量修改坐标
 * @param {Array<Object<{questionId: String, coordinates: Map}>>} data 题目ID对应分页选区对象
 * @returns {AxiosPromise}
 */
export function saveCoordinate (data) {
  return request({
    url: 'question/bank/saveCoordinate',
    method: 'POST',
    data
  })
}
