// 更改element主题色
import '@/theme/themeColor.scss'

import {
  Button,
  Form,
  FormItem,
  Input,
  Container,
  Header,
  Main,
  Select,
  Option,
  Table,
  TableColumn,
  Dialog,
  Radio,
  MessageBox,
  Footer,
  RadioGroup,
  Loading,
  Tree,
  Progress,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Tag,
  Pagination,
  Upload,
  Tabs,
  TabPane,
  Row,
  Col,
  Empty,
  InputNumber,
  Image,
  Checkbox,
  Cascader,
  RadioButton
} from 'element-ui'
import { message } from './message'

const elem = {
  install: function (Vue) {
    Vue.use(Button)
    Vue.use(Form)
    Vue.use(FormItem)
    Vue.use(Input)
    Vue.use(Container)
    Vue.use(Header)
    Vue.use(Main)
    Vue.use(Footer)
    Vue.use(Select)
    Vue.use(Option)
    Vue.use(Table)
    Vue.use(TableColumn)
    Vue.use(Dialog)
    Vue.use(Radio)
    Vue.use(RadioGroup)
    Vue.use(Loading)
    Vue.use(Tree)
    Vue.use(Progress)
    Vue.use(Dropdown)
    Vue.use(DropdownMenu)
    Vue.use(DropdownItem)
    Vue.use(Tag)

    Vue.use(Pagination)
    Vue.use(Upload)
    Vue.use(Tabs)
    Vue.use(TabPane)
    Vue.use(Row)
    Vue.use(Col)
    Vue.use(Empty)
    Vue.use(InputNumber)
    Vue.use(Image)
    Vue.use(Checkbox)
    Vue.use(Checkbox)
    Vue.use(Cascader)
    Vue.use(RadioButton)

    Vue.prototype.$alert = MessageBox.alert
    Vue.prototype.$confirm = MessageBox.confirm
    Vue.prototype.$message = message
  }
}

export default elem
