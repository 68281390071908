import request from '@/utils/request'
import { URLS } from '@/config/api'
import { SETTING } from '@/config/setting'

// 登录
export function login (parameter) {
  return request({
    url: URLS.USER_LOGIN,
    method: SETTING.HTTP.METHOD.POST,
    data: parameter
  })
}

// 退出登录
export function logout () {
  return request({
    url: URLS.USER_LOGOUT,
    method: SETTING.HTTP.METHOD.POST,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

// 发送验证码
export function sendSms (parameter) {
  return request({
    url: URLS.SEND_SMS,
    method: SETTING.HTTP.METHOD.POST,
    data: parameter,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

// 刷新token
export function refreshTokenFunction (refreshToken) {
  return request({
    url: URLS.REFRESH_TOKEN,
    method: SETTING.HTTP.METHOD.POST,
    headers: {
      Authorization: SETTING.CACHE.REFRESH_TOKEN + refreshToken,
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
