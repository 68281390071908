import request from '@/utils/request'

/**
 * 根据标准教材ID获取教材列表
 * @param {Number} standardMaterialId 学期版本Id
 * @returns {AxiosPromise}
 */
export function getStandardMaterialList (standardMaterialId) {
  return request({
    url: '/teachingMaterial/listByStandardMaterialId',
    method: 'GET',
    params: {
      standardMaterialId
    }
  })
}
