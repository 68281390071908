/**
 * 难易度 Difficulty
 */
export const Difficulty = [
  { label: '容易', value: 'SIMPLE' },
  { label: '较易', value: 'EASILY' },
  { label: '一般', value: 'COMMONLY' },
  { label: '较难', value: 'HARDER' },
  { label: '困难', value: 'DIFFICULTY' }
]
