import request from '@/utils/request'

/**
 * 知识点列表
 * @param {Number} subsectionSubjectId 学段学科ID
 * @returns {AxiosPromise}
 */
export function getKnowledgePointList (subsectionSubjectId) {
  return request({
    url: '/knowledge/point/list',
    method: 'GET',
    params: {
      subsectionSubjectId
    }
  })
}
