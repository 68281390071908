// 题目类型
export const QuestionType = [
  { id: 'CHOICE_QUESTION', value: '单选题' },
  { id: 'MULTIPLE_CHOICE_QUESTIONS', value: '多选题' },
  { id: 'CALCULATION_PROBLEMS', value: '计算题' },
  { id: 'JUDGMENT_QUESTIONS', value: '判断题' },
  { id: 'COMPLETION', value: '填空题' },
  { id: 'SHORT_ANSWER_QUESTIONS', value: '简答题' },
  { id: 'COMBINATION_QUESTIONS', value: '组合题' },
  { id: 'CLOZE_TEST', value: '完形填空' },
  { id: 'READ_QUESTIONS', value: '读一读' },
  { id: 'CONNECTED_QUESTIONS', value: '连线题' },
  { id: 'PROCESS_QUESTION', value: '流程题' },
  { id: 'COMPLETING_THE_DIALOGUE', value: '补全对话' },
  { id: 'READING_COMPREHENSION', value: '阅读理解' },
  { id: 'GRAMMAR_CHOICE', value: '语法选择' },
  { id: 'LITTLE_READING_EXTRACURRICULAR', value: '小阅读-课外' },
  { id: 'POETRY_APPRECIATION', value: '诗歌鉴赏' },
  { id: 'ESSAY_QUESTIONS', value: '论述题' },
  { id: 'GRAMMAR_FILL_IN_THE_BLANK', value: '语法填空' },
  { id: 'SINGLE_CHOICE', value: '单项选择' },
  { id: 'POPULAR_SCIENCE_READING_QUESTIONS', value: '科普阅读题' },
  { id: 'READING_REDUCTION', value: '阅读还原' },
  { id: 'LISTENING_QUESTIONS', value: '听力题' },
  { id: 'COMPREHENSIVE_INQUIRY_QUESTIONS', value: '综合探究题' },
  { id: 'COMPREHENSIVE_LEARNING', value: '综合性学习' },
  { id: 'LISTENING_CHOICE', value: '听力选择' },
  { id: 'FILL_IN_THE_BLANKS_ACCORDING_TO_THE_CHINESE_PROMPTS_GIVEN', value: '根据所给汉语提示填空' },
  { id: 'CLOZE', value: '完型填空' },
  { id: 'SUBJECTIVE_QUESTIONS', value: '主观题' },
  { id: 'PICTURE_PROBLEM', value: '识图题' },
  { id: 'MODERN_READING', value: '现代文阅读' },
  { id: 'INFORMATION_MATCHING', value: '信息匹配' },
  { id: 'LISTENING_MULTIPLE_CHOICE', value: '听力选择题' },
  { id: 'DISCRIMINATION_QUESTIONS', value: '辨析题' },
  { id: 'HEARING_JUDGMENT', value: '听力判断' },
  { id: 'WRITE_SENTENCES_ACCORDING_TO_THE_SITUATION', value: '根据情景写句子' },
  { id: 'SORTING_QUESTIONS', value: '排序题' },
  { id: 'COPY_QUESTIONS', value: '抄写题' },
  { id: 'ANSWER_THE_QUESTION', value: '解答题' },
  { id: 'DICTATION_AND_ESSAY', value: '默写与文常' },
  { id: 'EXPERIMENTAL_QUESTIONS', value: '实验题' },
  { id: 'MATERIAL_ANALYSIS_QUESTIONS', value: '材料分析题' },
  { id: 'CHOICE_OF_WORDS_FILL_IN_THE_BLANK', value: '选词填空' },
  { id: 'BEST_CHOICE', value: '最优选择题' },
  { id: 'LISTENING_FILL_IN_THE_BLANKS', value: '听力填空' },
  { id: 'DIALOGUE_FILL_IN_THE_BLANKS', value: '对话填空' },
  { id: 'MODIFY_THE_SICK_SENTENCE', value: '修改病句' },
  { id: 'SITUATIONAL_INQUIRY_QUESTIONS', value: '情境探究题' },
  { id: 'CLASSIC_READING', value: '名著阅读' },
  { id: 'WRITTEN_EXPRESSION', value: '书面表达' },
  { id: 'WRITE', value: '书写' },
  { id: 'PART_OF_SPEECH', value: '词性转换' },
  { id: 'TASK_BASED_READING', value: '任务型阅读' },
  { id: 'SHORT_ESSAY', value: '短文填空' },
  { id: 'FILL_IN_THE_BLANKS_ACCORDING_TO_THE_MEANING_OF_THE_SENTENCE', value: '根据句意填空' },
  { id: 'APPRECIATION_OF_POETRY_LYRICS_AND_MUSIC', value: '诗词曲鉴赏' },
  { id: 'COMPREHENSIVE_QUESTIONS', value: '综合题' },
  { id: 'LANGUAGE_EXPRESSION', value: '语言表达' },
  { id: 'PHONETIC_QUESTION', value: '语音题' },
  { id: 'FILL_IN_THE_BLANKS_WITH_THE_CORRECT_FORM_OF_THE_GIVEN_WORD', value: '用所给单词的正确形式填空' },
  { id: 'TRANSLATION_QUESTIONS', value: '翻译题' },
  { id: 'FINISH_THE_SENTENCE', value: '完成句子' },
  { id: 'DRAWING_QUESTIONS', value: '画图题' },
  { id: 'OTHER', value: '其他' },
  { id: 'LISTENING_RANKING', value: '听力排序' },
  { id: 'READING_AND_WRITING_SYNTHESIS', value: '读写综合' },
  { id: 'CLASSICAL_CHINESE_READING', value: '文言文阅读' },
  { id: 'DATA_ANALYSIS_QUESTIONS', value: '资料分析题' },
  { id: 'NON_CHOICE_QUESTIONS', value: '非选择题' },
  { id: 'IMITATION', value: '仿写' },
  { id: 'EXPERIMENTAL_INQUIRY_QUESTIONS', value: '实验探究题' },
  { id: 'ANALYSIS_EXPLANATORY_QUESTIONS', value: '分析说明题' },
  { id: 'LISTENING_AND_APPRECIATION', value: '听读鉴赏' },
  { id: 'ANCIENT_POETRY_READING', value: '古代诗歌阅读' },
  { id: 'SENTENCE_PATTERN_CONVERSION', value: '句型转换' },
  { id: 'DOUBLE_EMPTY_QUESTION', value: '双空题' },
  { id: 'SPELLING', value: '单词拼写' },
  { id: 'BASIC_KNOWLEDGE_SYNTHESIS', value: '基础知识综合' },
  { id: 'IN_CLASS_FILL_IN_THE_BLANKS', value: '课内填空' },
  { id: 'INFERENCE_QUESTIONS', value: '推断题' },
  { id: 'LETTER_WRITING', value: '书信写作' },
  { id: 'SCIENTIFIC_INQUIRY_QUESTIONS', value: '科学探究题' },
  { id: 'LETTER_PROBLEM', value: '字母题' },
  { id: 'CONJUNCTION_AND', value: '连词成句' },
  { id: 'SMALL_READING_IN_CLASS', value: '小阅读-课内' },
  { id: 'COMPREHENSIVE_WORD_PROBLEMS', value: '综合应用题' },
  { id: 'TRANSLATION', value: '翻译' },
  { id: 'COMPLETION_OF_DIALOGUE', value: '补全对话/短文' },
  { id: 'OTHER_READING_QUESTIONS', value: '其他阅读题型' },
  { id: 'LIST_THE_TOPIC', value: '列举题' },
  { id: 'CLASSICAL_CHINESE_ITEM', value: '文言小题' },
  { id: 'WRITE_FAMOUS_SENTENCES_AND_PASSAGES_SILENTLY', value: '名句名篇默写' },
  { id: 'WORDS_WRITTEN', value: '字词书写' },
  { id: 'JUDGE_THE_ARGUMENT', value: '判断说理题' },
  { id: 'SENTENCE_ORDER', value: '句子排序' },
  { id: 'PRAGMATIC_SYNTHESIS', value: '语用综合题' },
  { id: 'CHOOSE_FILL_IN_QUESTIONS', value: '选择填充题' },
  { id: 'LISTENING_MATCHING', value: '听力匹配题' },
  { id: 'MAPPING_PROBLEM', value: '作图题' },
  { id: 'MATCHING_QUESTIONS', value: '匹配题' },
  { id: 'SUBJECTS_WERE', value: '改错' },
  { id: 'CHANGE_THE_WRONG_TOPIC', value: '改错题' },
  { id: 'THE_COMPOSITION', value: '作文' },
  { id: 'APPLICATION_QUESTIONS', value: '应用题' },
  { id: 'GEOMETRICAL', value: '几何' },
  { id: 'DIGITAL_READING', value: '数字阅读' },
  { id: 'MULTIPLE_CHOICE', value: '选择题' },
  { id: 'OPERATION_PROBLEM', value: '操作题' },
]
