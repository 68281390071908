<template>
  <el-container class="container">
    <el-header class="backgrounImg">
      <div class="avatar_info">
        <img src="/logo-fff.png" alt="">
        <div class="column_style">|</div>
        <div class="top_title">作业评价-录题系统</div>
      </div>
      <div class="tabbar_change">
        <div class="tabbar_item" :class="{borderStyle: changeState === 1}" @click="navToList(1)">题库列表</div>
        <div class="tabbar_item" :class="{borderStyle: changeState === 2}" @click="navToSubject(2)">新增题目</div>
        <div class="tabbar_item" :class="{borderStyle: changeState === 3}" @click="navToUpload(3)">题目上传</div>
      </div>
      <div class="exit_login">
        <div class="display">
          <img class="marginStyle" src="../../assets/images/header_label.png" alt="">
          <div>{{ phone }}</div>
        </div>
        <div class="display">
          <div class="marginStyle column_s">|</div>
          <img class="leave_login" src="../../assets/images/exit_login.png" alt="" @click="dialogVisible = true">
          <div class="leave_login" @click="dialogVisible = true"></div>
        </div>
        <el-dialog
          :visible.sync="dialogVisible"
          width="25%"
        >
          <div style="font-size:15px;text-align:center">是否确认退出登录？</div>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button @click="exit" type="primary">确 定</el-button>
          </div>
        </el-dialog>
      </div>
    </el-header>
    <div class="el_main">
      <router-view></router-view>
    </div>
  </el-container>
</template>

<script>
import { logout } from '@/api/login'
import { SETTING } from '@/config/setting'
import storage from 'store'

export default {
  computed: {
    phone () {
      return this.$nStore.get(SETTING.CACHE.USER_PHONE)
    }
  },
  data () {
    return {
      changeState: 1,
      dialogVisible: false
    }
  },
  created () {
    this.changeState = Number(localStorage.getItem('num')) ? Number(localStorage.getItem('num')) : 1
  },
  methods: {
    exit () {
      logout().then(response => {
        if (response.code === SETTING.NUMBER.ZERO) {
          storage.clearAll()
          this.$router.push('/login')
        }
      })
    },
    navToList (num) {
      localStorage.setItem('num', num)
      this.changeState = num
      this.$router.push('/list/')
    },
    navToSubject (num) {
      localStorage.setItem('num', num)
      this.changeState = num
      this.$router.push('/addtopic')
    },
    navToUpload (num) {
      // localStorage.setItem('num', num)
      // this.changeState = num
      open(process.env.VUE_APP_STATIC + '/backage-js/backImageToLatex/index.html')
    }
  }
}
</script>

<style lang="scss" scoped>
.el_main {
  padding: 0;
  height: calc(100% - 60px);
  //overflow-x: scroll;
}

.top_title{
  min-width: 125px;
}

.container {
  width: 100%;
  height: 100%;
}

.el-header {
  height: 50px;
  // background-color: #1EB180;
  background-image: url(../../assets/images/header_bg.png);
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  font-size: 14px;
  font-family: 'PingFangSC';

  .avatar_info {
    // margin-left: 16px;
    display: flex;
    align-items: center;

    img {
      width: 25px;
      height: 25px;
      object-fit: contain;
    }

    .column_style {
      margin: 0 8px;
      font-size: 14px;
    }
  }

  .tabbar_change {
    display: flex;
    align-items: center;
    margin-left: 160px;

    .tabbar_item {
      margin-right: 180px;
      font-size: 14px;
      cursor: pointer;
      min-width: 60px;
    }
  }

  .exit_login {
    height: 100%;
    width: 373px;
    display: flex;
    justify-content: flex-end;

    img {
      width: 20px;
      height: 20px;
    }

    .column_s {
      font-size: 18px;
    }

    .leave_login {
      white-space: nowrap;
      margin-left: 10px;
      cursor: pointer;
    }
  }

  .borderStyle {
    border-bottom: 3px solid #FFFFFF;
  }
}

.itemlabel .el-form-item__label {
  font-size: 14px;
  font-weight: none;
}
/deep/ .el-button--primary{
  background-color: #1EB180;
  border: 1px solid #1EB180;
}
</style>
