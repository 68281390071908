<template>
  <el-dialog
    class="ui-gallery"
    destroy-on-close
    top="0"
    :center="true"
    :title="title"
    :visible="visible"
    :before-close="handleClose"
  >
    <Gallery :index="0" :list="list" />
    <slot name="footer"></slot>
  </el-dialog>
</template>

<script>
import Gallery from './Gallery'

export default {
  name: 'UiGallery',
  components: {
    Gallery
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: '预览'
    },
    index: {
      type: Number,
      default: 0
    },
    list: {
      type: Array,
      default () {
        return []
      }
    }
  },
  methods: {
    handleClose (done) {
      done()
      this.$emit('update:visible', false)
    }
  }
}
</script>

<style lang="scss">
.ui-gallery {
  display: flex;
  justify-content: space-around;
  align-items: center;

  &-left,
  &-right {
    position: absolute;
    bottom: 50%;
    font-size: 25px;
    cursor: pointer;
    z-index: 9;
  }

  &-left {
    left: 0;
  }

  &-right {
    right: 0;
  }

  &-container {
    height: calc(90vh - 110px);
    width: calc(70vh - 90px);
    max-width: calc(100vw - 50px);
    min-width: 300px;
    min-height: 400px;

    > .preview {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }

  .el-dialog {
    margin: 0 auto;
    width: auto;
  }
}
</style>
