import { v4 } from 'uuid'

/**
 * 获取UUID
 * @returns {*|string}
 */
export const getUuid = v4

/**
 * 获取日期(年-月-日)
 */
export function getDate (delimiter = '-') {
  const date = new Date()
  const Y = date.getFullYear()
  const M = date.getMonth() + 1
  const d = date.getDate()
  return Y + delimiter + (M > 9 ? M : '0' + M) + delimiter + (d > 9 ? d : '0' + d)
}

/**
 * 获取图片后缀
 * @param {string} fileName
 * @returns {string}
 */
export function getFileExtension (fileName) {
  if (fileName) {
    const idx = fileName.lastIndexOf('.')
    if (idx > 0) {
      return fileName.substring(idx)
    }
  }
  return ''
}
