import Editor from './Editor/Editor'
import Gallery from './Gallery'
import Mathpad from './Mathpad/Mathpad'
import Pagination from './Pagination/pagination'
import Preview from './Preview/Preview'
import SectionPicker from './SectionPicker'
import Uploader from './Uploader/Uploader'
import Audio from './Audio/index'
import AudioPlayer from './Audio/AudioPlayer'
import DifficultyPicker from './DifficultyPicker'

export default function install (Vue) {
  Vue.component('ui-editor', Editor)
  Vue.component('ui-gallery', Gallery)
  Vue.component('ui-mathpad', Mathpad)
  Vue.component('ui-pagination', Pagination)
  Vue.component('ui-preview', Preview)
  Vue.component('ui-section-picker', SectionPicker)
  Vue.component('ui-uploader', Uploader)
  Vue.component('ui-audio', Audio)
  Vue.component('ui-audio-player', AudioPlayer)
  Vue.component('ui-difficulty-picker', DifficultyPicker)
}
