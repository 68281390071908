<template>
  <div class="ui-mathpad" ref="wrapper" :class="{ done: !loading }">
    <div ref="mathpad" v-html="value" />
  </div>
</template>

<script>
// import { MathQueue } from './index'

export default {
  name: 'UiMathpad',
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  watch: {
    value: {
      handler (val) {
        if (val) {
          this.loading = true
          this.$nextTick(() => {
            const dom = this.$refs.mathpad
            // MathQueue(dom)
            window.MathQueue([dom])
            this.loading = false
          })
        }
      },
      immediate: true
    }
  },
  data () {
    return {
      loading: true
    }
  }
}
</script>

<style lang="scss" scoped>
.ui-mathpad {
  transition: all 0.3s;
  opacity: 0;
  max-width: 800px;
  word-wrap: break-word;

  &.done {
    opacity: 1;
  }

  /deep/ img {
    max-width: 100%;
    max-height: 800px;
  }
}
</style>
