<template>
  <el-dialog
    :close-on-click-modal="false"
    :show-close="false"
    :visible.sync="visible"
    width="800px"
    center
  >
    <div class="wrapper">
      <div class="wrapper-panel">
        <div class="wrapper-title"><p>原题</p></div>
        <div class="wrapper-main">
          <ui-mathpad v-if="origin" :value="origin.stem" />
        </div>
      </div>
      <div class="wrapper-panel">
        <div class="wrapper-title"><p>相似题</p></div>
        <div class="wrapper-main group-bg">
          <el-checkbox-group :value="checkedData" size="mini" @input="handleInput">
            <div class="context" v-for="(item, index) in list" :key="index">
              <div class="headers">
                <el-checkbox :label="item.bankId">
                  <span class="similar">第{{ index + 1 }}题</span>
                </el-checkbox>
              </div>
              <ui-mathpad class="contents" :value="item.stem" />
            </div>
          </el-checkbox-group>
        </div>
      </div>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button type="default" size="small" @click="close">取 消</el-button>
      <el-button type="primary" size="small" @click="submit">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { CheckboxGroup } from 'element-ui'

export default {
  name: 'CheckRepeat',
  components: {
    'el-checkbox-group': CheckboxGroup
  },
  data () {
    return {
      visible: false,
      origin: null,
      list: [],

      checkedData: [],
    }
  },
  methods: {
    handleInput (val) {
      this.checkedData = val.slice(-1)
    },

    show (origin, list) {
      this.checked = ''
      // this.stem = origin.stem
      this.origin = origin
      this.list = list

      this.visible = true
    },

    submit () {
      this.$emit('submit', {
        bankId: this.checkedData,
        origin: this.origin
      })
      this.close()
    },

    close () {
      this.visible = false
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog-footer {
  margin-top: -10px;
  .el-button {
    width: 120px;
  }
}

.wrapper {
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  &-panel {
    width: 49%;
    border: 1px solid #1EB180;
    border-radius: 10px;
    overflow: hidden;
  }

  &-title {
    font-size: 15px;
    line-height: 42px;
    font-weight: 600;
    padding: 0 20px;
    background: #E8F7F2;
    color: #1EB180;
  }

  &-main {
    padding: 10px;
    height: 520px;
    overflow: auto;

    &.group-bg {
      background: #F6F6F6;
    }
  }

  .context {
    font-size: 14px;
    margin-bottom: 10px;
    background-color: #fff;
    padding: 10px;
    border: 1px solid #eee;
    border-radius: 5px;
  }
}
</style>
