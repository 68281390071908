<template>
  <el-cascader
    ref="cascader"
    v-model="nodeIds"
    v-bind="$attrs"
    :options="editionGradeOptions"
    @change="handleChange"
  />
</template>

<script>
import {
  commonMaterialList,
} from '@/api/question'

export default {
  name: 'FormItemEdition',
  props: {
    loadOptions: {
      type: String,
      validator (m) {
        return m === 'auto' || m === 'custom'
      },
      default: 'auto'
    },
    value: {
      type: Number,
      default: undefined
    },
    subsectionId: {
      type: Number,
      default: undefined
    }
  },
  data () {
    return {
      subjectList: [],

      editionGradeOptions: [],
      nodeIds: [],
    }
  },
  watch: {
    value: {
      immediate: true,
      handler (val) {
        this.nodeIds = this.getNodeIds(val)
      }
    },
    subsectionId: {
      immediate: true,
      handler (val) {
        if (val) {
          if (this.loadOptions === 'auto') {
            this.loadData(val)
          }
        } else {
          this.editionGradeOptions = []
        }
      }
    }
  },
  methods: {
    loadData (subsectionId, subjectName) {
      return commonMaterialList(subsectionId, subjectName).then(res => {
        if (res.code === 0) {
          const list = res.data || []
          list.forEach(m => { m.id = m.sort })
          this.editionGradeOptions = list
          this.nodeIds = this.getNodeIds(this.value)
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    getNodeIds (id) {
      if (id && this.editionGradeOptions.length) {
        const idx = this.editionGradeOptions.findIndex(edition =>
          edition.children.findIndex(grade => grade.value === id) >= 0
        )
        if (idx >= 0) {
          return [this.editionGradeOptions[idx].value, id]
        }
      }
      return []
    },

    handleChange (val) {
      const data = val.length ? val[val.length - 1] : undefined
      this.$emit('input', data)
      const checkedNodes = this.$refs.cascader.getCheckedNodes()
      this.$emit('change', checkedNodes && checkedNodes.length ? checkedNodes[0] : null)
    }
  }
}
</script>
