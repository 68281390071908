<template>
  <div class="ui-audio">
    <el-button :type="type" @click="showPlayer" icon="el-icon-headset">听力</el-button>
    <el-dialog
      width="420px"
      custom-class="ui-audio-dialog"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      destroy-on-close
    >
      <div v-if="textShow">
        <div v-if="resourceList.length" class="ui-audio-text">
          <div v-for="(item, index) in resourceList" :key="index" v-html="item"></div>
        </div>
      </div>
      <div class="player-wrapper">
        <ui-audio-player ref="media" class="ui-audio-player" :src="src" />

        <div>
          <el-button
            type="text"
            :disabled="resourceList.length === 0"
            :title="resourceList.length === 0 ? '暂无听力材料' : ''"
            @click="textShow = !textShow"
          >听力材料</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import AudioPlayer from './AudioPlayer'

export default {
  name: 'UIAudio',
  components: {
    'ui-audio-player': AudioPlayer,
  },
  props: {
    src: { // 音频Url
      type: String,
      default: '',
    },
    text: { // 音频文本（听力文本）
      type: String,
      default: ''
    },
    type: { // 按钮样式
      type: String,
      default: 'normal',
    },
    autoplay: { // 自动播放
      type: Boolean,
      default: false,
    },
  },
  computed: {
    resourceList () {
      const cnt = (this.text || '').trim()
      return cnt.length > 0 ? cnt.split('\n') : []
    }
  },
  data () {
    return {
      dialogVisible: false,
      textShow: false
    }
  },
  beforeDestroy () {
    this.dialogVisible = false
  },
  methods: {
    showPlayer () {
      this.textShow = false
      this.dialogVisible = true
      if (this.autoplay) {
        this.$nextTick(this.play)
      }
    },

    handleClose (done) {
      this.pause()
      done()
    },

    play () {
      const media = this.$refs.media
      if (media) {
        media.play()
      }
    },

    pause () {
      const media = this.$refs.media
      if (media) {
        media.pause()
      }
    },
  }
}
</script>

<style lang="scss">
.ui-audio {
  &-dialog {
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 10px;

    .el-dialog__header {
      //display: none;
      padding: 0;
    }
    .el-dialog__headerbtn {
      top: auto;
      bottom: 18px;
    }
    .el-dialog__body {
      padding: 8px 20px;
    }

    .player-wrapper {
      display: flex;
      align-items: center;
      margin-right: 15px;

      button {
        padding: 0 10px;
      }
    }
  }

  .text-empty {
    padding-top: 0;
  }

  &-text {
    margin: 0 0 10px;
    max-height: 260px;
    overflow-y: auto;
    font-weight: normal;
    color: #333;
  }

  &-player {
    display: block;
    width: 300px;
    height: 40px;
    max-width: 100%;
  }
}
</style>
