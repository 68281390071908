import axios from 'axios'
import { VueAxios } from './axios'
import { SETTING } from '@/config/setting'
import { NStore } from '@/utils/common.fun'
import { message } from '@/element/message'

// 创建 axios 实例
const request = axios.create({
  // API 请求的默认前缀
  baseURL: process.env.VUE_APP_API_BASE + '/backstage',
  // 请求超时时间
  timeout: 20000
})

// 异常拦截处理器
const errorHandler = (error) => {
  if (error.isAxiosError) {
    console.error(error.response || error.message)
  }
  message.error('请求异常')
  // 处理异常拦截
  return Promise.reject(error)
}

// request interceptor
request.interceptors.request.use(config => {
  if (config.url === '/api/user-login' || config.url === '/sms/behavior/verify' || config.url === '/api/refresh-token') {
    return config
  }
  const accessToken = NStore.get(SETTING.CACHE.ACCESS_TOKEN)
  const provinceId = NStore.get(SETTING.CACHE.PROVINCE_ID)
  if (!accessToken) {
    // 暂无刷新token接口 - token 失效跳转登录页
    window.location.href = '/login'
    throw new Error('请登录系统')

    // const refreshToken = NStore.get(SETTING.CACHE.REFRESH_TOKEN)
    // if (refreshToken) {
    //   console.log('刷新token')
    //   refreshTokenFunction(refreshToken).then(result => {
    //     accessToken = result.data.access_token
    //     NStore.set(SETTING.CACHE.ACCESS_TOKEN, result.data.access_token, new Date().getTime() + result.data.expires_in * 1000)
    //   })
    // }
  }
  config.headers.Authorization = 'token ' + accessToken
  config.headers['province-Id'] = provinceId
  return config
}, errorHandler)

// response interceptor
request.interceptors.response.use((response) => {
  if (response.data.code === 930003) {
    NStore.clearAll()
    window.location = '/login'
  }
  return response.data
}, errorHandler)

const installer = {
  vm: {},
  install (Vue) {
    Vue.use(VueAxios, request)
  }
}

export default request

export {
  installer as VueAxios,
  request as axios
}
