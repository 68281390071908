import Vue from 'vue'
import VueRouter from 'vue-router'

import Layout from '@/views/questionBank/home.vue'
import Question from './Question.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/login/Login.vue')
  },
  {
    path: '/home',
    name: 'Home',
    redirect: '/batchImport',
    component: Layout,
    children: [
      {
        path: '/list/:page([1-9]\\d*)?',
        name: 'List',
        component: () => import('../views/questionBank/List.vue')
      },
      {
        path: '/topic/:id',
        name: 'Topic',
        component: () => import('../views/questionBank/Topic.vue')
      },
      {
        path: '/Edit/:id',
        name: 'Edit',
        component: () => import('../views/questionBank/Edit.vue')
      },
      {
        path: '/addtopic',
        name: 'Addtopic',
        component: () => import('../views/questionBank/Addtopic.vue')
      },
      {
        path: '/errorCorrect/:id',
        name: 'ErrorCorrect',
        component: () => import('../views/questionBank/errorCorrect.vue')
      }
    ]
  },
  Question,
  {
    path: '/biHome',
    name: 'BiHome',
    redirect: '/batchImport',
    component: () => import('@/views/questionBank/modules/Layout'),
    meta: { type: 'basic' },
    children: [
      {
        path: '/batchImport',
        name: 'BatchImport',
        component: () => import('../views/basicoperation/batchImport.vue'),
        meta: { keepAlive: true }
      },
      {
        path: '/batchDrawCoordinates',
        name: 'BatchDrawCoordinates',
        component: () => import('../views/basicoperation/batchDrawCoordinates.vue')
      },
      {
        path: '/drawCoordinates/:teachingMaterialId(\\d+)?',
        name: 'DrawCoordinates',
        component: () => import('../views/basicoperation/drawCoordinates.vue')
      },
      // {
      //   path: '/pictureRecognition',
      //   name: 'pictureRecognition',
      //   component: () => import('../views/basicoperation/errorCorrectAdd.vue')
      // },
      {
        // 基础性作业 题目编辑器
        path: '/pictureRecognition',
        name: 'pictureRecognition',
        component: () => import('../views/basicoperation/editor/index.vue'),
      },
    ]
  },
  {
    path: '/platHome',
    name: 'PlatHome',
    redirect: '/platformQuestion',
    component: () => import('@/views/questionBank/modules/Layout'),
    meta: { type: 'platform' },
    children: [
      {
        path: '/platformQuestion',
        name: 'PlatformQuestion',
        component: () => import('../views/platformQuestion/index.vue'),
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// router.addRoute(require('../views/test/routes').default)

export default router
