<template>
  <el-dialog
    class="ui-preview"
    :center="true"
    :title="title"
    :visible="innerVisible"
    :before-close="handleClose"
    destroy-on-close
    width="800px"
  >
    <div v-if="innerType === 'image'">
      <img :src="innerUrl">
    </div>
    <div v-else-if="innerType === 'video'">
      <video ref="Media" :src="innerUrl" controls autoplay></video>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'UiPreview',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: '预览'
    },
    type: {
      type: String,
      default: 'image' // image: 图片, video: 视频
    },
    url: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      innerVisible: false,
      innerType: 'image',
      innerUrl: ''
    }
  },
  watch: {
    type: {
      immediate: true,
      handler (val) {
        if (val) {
          this.innerType = val
        }
      }
    },
    url: {
      immediate: true,
      handler (val) {
        if (val) {
          this.innerUrl = val
        }
      }
    },
    visible: {
      immediate: true,
      handler (val) {
        this.innerVisible = val
      }
    }
  },
  methods: {
    show (url, type) {
      if (type) {
        this.innerType = type
      }
      this.innerUrl = url
      this.innerVisible = true
    },

    stop () {
      if (this.innerType === 'video') {
        this.$refs.Media && this.$refs.Media.pause()
      }
    },

    handleClose (done) {
      done()
      this.stop()
      this.innerVisible = false
      this.$emit('update:visible', false)
    }
  },
  beforeDestroy () {
    // 停止音视频播放
    this.stop()
  }
}
</script>

<style lang="scss">
.ui-preview {
  video {
    display: block;
    height: 430px;
    width: 750px;
  }

  img {
    width: 100%;
    max-height: calc(100vh - 350px);
    object-fit: contain;
  }
}
</style>
