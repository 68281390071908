import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import '../src/assets/css/index.css'
import '../src/assets/css/main.css'
import '../src/assets/css/style.css'
import './components/Editor/question.scss'
import { NStore } from '@/utils/common.fun'
import Common from '@/drawTool/common'

import element from './element/index'
import Components from './components'

import ossClient from '@/components/Uploader/ossClient'

Vue.prototype.$oss = ossClient
Vue.prototype.$nStore = NStore
Vue.prototype.commonsVariable = { MathQueue: window.MathQueue }
Vue.prototype.$common = new Common()

Vue.use(element)
Vue.use(Components)

// Vue.prototype.$message = message
Vue.config.productionTip = false

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
