<template>
  <!-- controlslist="nodownload noplaybackrate" -->
  <audio
    ref="media"
    controls
    controlslist="nodownload"
    :title="src.length ? '' : '暂无音频'"
  >
    <source :src="src" v-if="src">
  </audio>
</template>

<script>
export default {
  name: 'AudioPlayer',
  props: {
    src: {
      type: String,
      default: '',
    },
  },
  beforeDestroy () {
    this.pause()
  },
  methods: {
    play () {
      const media = this.$refs.media
      if (media) {
        media.play()
      }
    },

    pause () {
      const media = this.$refs.media
      if (media) {
        media.pause()
      }
    },
  }
}
</script>
